import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	CheckboxList,
	RadioList,
	RequestFormPDFProps,
	RequestInputFiles,
	RequestInputs,
	UpdateRequestInputsArray,
	SelectList,
	Steps as DefaultSteps,
	StepsUrbanism
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse as ReactCollapse } from 'react-collapse'
import Collapse from '@components/ui/collapse'
import {
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME,
	Direction,
	requestCategories,
	documentStatusConfig,
	requestSubCategories
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import TextInput from './inputs/text.inputs'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import { AddressCoordinates, CustomDate } from '@services/models'
import { formatStrapiText, getFilesFromStore } from '@utils/methods'
import * as formUtils from '@pages/auth/__index.utils'
import {
	PHONE_NUMBER_REGEX,
	POSTAL_CODE_REGEX
} from '@pages/auth/profile/__index.utils'
import MaskedTextInput from '@components/requestForms/inputs/textWithValidation.inputs'
import Text from '@components/ui/text'
import RequestFiles from '@components/ui/requestFiles'
import DateFilterInput from './inputs/datefilter.input'
import RadioInput from './inputs/radio.input'
import AddressInfoSection from '@components/ui/addressInfosSection'
import Notification from '@components/ui/notification'
import TextAreaInput from './inputs/textarea.inputs'
import CheckboxInput from './inputs/checkbox.input'
import parse from 'html-react-parser'
import { formatHtmlForStrapiText } from '@utils/methods'
import SelectInput from './inputs/select.inputs'
import { getAddressByCoordinates } from '@components/ui/addressForm/__index.utils'
import AddressVoiePublicSection from '@components/ui/addressVoiePublicSection'
import MaskedThousandsSeparatorInput from './inputs/textWithThousandsSeparator.inputs'
import { formatNumberWithSpaces } from './__forms.utils'
import {
	amenagementBalcon,
	amenagementGalerie,
	amenagementperron,
	amenagementporche
} from '@images/icons'

const LandDevelopmentWorkPermitForm: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	inputFiles,
	isSummaryStep,
	steps,
	toPrint,
	filesInputs,
	formPosition,
	setDisableNextButton,
	onCustomError,
	onSetInputFiles,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep,
	onSetCurrentSubStep,
	setStepsList,
	setSteps
}) => {
	const {
		authUser,
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputFilesArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	type Classes = {
		button: string
		outlinedButton: string
		buttonContainer: string
		fileHelperSection: string
		fileContainer: string
		inputContainer: string
		checkbox2: string
		checkbox2Label: string
		checkbox2Container: string
		checkboxWrapper: string
		adresseBlock: string
		h5: string
		greyBackgroundBlock: string
		detailscontainer: string
		subContent: string
		reducedMarginBottom: string
		image: string
	}

	const classes: Classes = makeClasses({
		button: {
			display: 'inline-block',
			padding: '10px 20px',
			fontSize: '13px',
			fontWeight: '500',
			color: Colors.white,
			lineHeight: '1em',
			borderRadius: '23px',
			transition: 'all 0.35s ease',
			MsTransition: 'all 0.35s ease',
			background: Colors.secondary,
			border: 'none',
			cursor: 'pointer',
			'&:hover': {
				background: Colors.darkBlue2,
				borderColor: Colors.darkBlue2,
				color: Colors.white
			}
		},
		outlinedButton: {
			background: 'none',
			border: `1px solid ${Colors.secondary} !important`,
			color: Colors.secondary
		},

		buttonContainer: {
			display: 'flex',
			width: 'fit-content',
			columnGap: '4px'
		},
		fileHelperSection: {
			background: Colors.darkWhite,
			padding: '30px',
			marginBottom: '15px'
		},
		fileContainer: {
			'.blue-bullets-list': {
				ul: {
					padding: '0px 15px 0px 20px'
				}
			}
		},
		inputContainer: {
			marginBottom: '20px'
		},

		checkbox2: {
			width: 'fit-content',
			position: 'absolute',
			verticalAlign: 'baseline',
			clip: 'rect(1px, 1px, 1px, 1px)'
		},
		checkbox2Label: {
			fontSize: '16px',
			padding: '0',
			color: Colors.lightBlack,
			position: 'relative',
			paddingLeft: '27px',
			fontWeight: 'normal',
			cursor: 'pointer',
			lineHeight: '17px',
			':before': {
				content: "''",
				position: 'absolute',
				top: '0',
				left: '0',
				width: '16px',
				height: '16px',
				border: `1px solid ${Colors.lightGrey3}`,
				background: Colors.white,
				outline: 'none !important'
			},
			':after': {
				content: "''",
				position: 'absolute',
				top: '4px',
				left: '4px',
				width: '10px',
				height: '10px',
				background: Colors.secondary,
				outline: 'none',
				opacity: '0',
				transition: 'all .15s ease-out'
			}
		},
		checkbox2Container: {
			marginTop: '-15px',
			marginBottom: '15px',
			'& input[value=true] ~ label:after': {
				opacity: '.99'
			}
		},
		checkboxWrapper: {
			display: 'block',
			margin: '0 0 5px 0',
			background: Colors.ligthGrey2,
			border: `1px solid ${Colors.greyAlto}`,
			padding: '14px 12px'
		},
		adresseBlock: {
			border: `1px solid ${Colors.greyAlto}`,
			padding: '20px 25px',
			marginBottom: '10px',
			'& div': {
				alignItems: 'start !important'
			}
		},
		h5: {
			fontSize: '20px',
			fontWeight: '600',
			margin: '15px 0 10px 0'
		},
		greyBackgroundBlock: {
			background: Colors.darkWhite,
			padding: '15px 30px'
		},
		detailscontainer: {
			display: 'grid',
			alignItems: 'start',
			gridTemplateColumns: '50%43%',
			columnGap: '7%',
			[Breakpoints.maxWidth('lg')]: {
				gridTemplateColumns: '45%46%',
				columnGap: '12%'
			},
			[Breakpoints.maxWidth('md')]: {
				gridTemplateColumns: '100%',
				'& img': {
					marginTop: '15px !important',
					width: '80% !important'
				}
			},
			[Breakpoints.maxWidth('sm')]: {
				'& img': {
					width: '100% !important'
				}
			},
			lineHeight: '1.62em',
			// fontSize: '16px',
			color: Colors.lightBlack,
			'& img': {
				width: '100%',
				verticalAlign: 'middle',
				margin: 'auto'
			},
			'& ul': {
				paddingLeft: '18px',
				margin: 0,
				'& li::marker': {
					color: Colors.secondary
				}
			}
		},
		subContent: {
			display: 'grid',
			columnGap: '15px',
			rowGap: '10px',
			gridTemplateColumns: '10rem auto',
			alignItems: 'end',
			[Breakpoints.maxWidth('md')]: {
				gridTemplateColumns: '2fr auto'
			},
			[Breakpoints.maxWidth('sm')]: {
				gridTemplateColumns: '2fr auto'
			},
			[Breakpoints.minWidth('md')]: {
				'& span': {
					'white-space': 'nowrap'
				}
			}
		},
		reducedMarginBottom: {
			marginBottom: '18px'
		},
		image: {
			[Breakpoints.maxWidth('md')]: {
				width: '35%'
			}
		}
	})

	const answers = useAppSelector((state) => state.request.configs)
	const location = useAppSelector((state) => state.request.location)

	const isNewConstruction = (value) =>
		value?.toString() ==
		REQUEST_CONFIGS_KEYS.whatToDoBuildingConstructionOrAdditionPermit
			.request_new_construction

	const isAddition = (value) =>
		value?.toString() ==
		REQUEST_CONFIGS_KEYS.whatToDoBuildingConstructionOrAdditionPermit
			.request_addition

	const [showAdresseBlock, setShowAdresseBlock] = useState<boolean>(
		answers?.buildingAddressSameHasApplicant?.toString() == 'false'
			? true
			: false
	)

	const isDemolition = (value) =>
		value?.toString() ==
		REQUEST_CONFIGS_KEYS.whatToDoBuildingDemolitionOrMovingPermit
			.request_demolition

	const isMoving = (value) =>
		value?.toString() ==
		REQUEST_CONFIGS_KEYS.whatToDoBuildingDemolitionOrMovingPermit.request_moving

	const isBalconyOrPorchPermit = (value) =>
		value?.toString() ==
		REQUEST_CONFIGS_KEYS.whatToDoLandDevelopmentWorkPermit.firstOption

	//----------------------------------------------------------------
	//--------------------- DETAILS 1 INPUTS -------------------------
	//----------------------------------------------------------------

	// ************* Type de demande ***********

	// Que souhaitez-vous faire?
	const [whatToDoInput, setWhatToDoInput] = useState<RequestInputs>({
		name: 'whatToDo',
		label: pageAssets?.request_form_what_would_you_like_to_do,
		labelKey: 'request_form_what_would_you_like_to_do',
		value: answers?.whatToDo?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	// LandDevelopmentWorkPermitForm
	const radioListWhatToDo: RadioList[] = [
		{
			label:
				pageAssets?.request_form_landDevelopmentWorkPermit_whatToDo_firstOption,
			value: REQUEST_CONFIGS_KEYS.whatToDoLandDevelopmentWorkPermit.firstOption,
			key: 'request_form_landDevelopmentWorkPermit_whatToDo_firstOption'
		},
		{
			label:
				pageAssets?.request_form_landDevelopmentWorkPermit_whatToDo_secondOption,
			value:
				REQUEST_CONFIGS_KEYS.whatToDoLandDevelopmentWorkPermit.secondOption,
			key: 'request_form_landDevelopmentWorkPermit_whatToDo_secondOption'
		},
		{
			label:
				pageAssets?.request_form_landDevelopmentWorkPermit_whatToDo_thirdOption,
			value: REQUEST_CONFIGS_KEYS.whatToDoLandDevelopmentWorkPermit.thirdOption,
			key: 'request_form_landDevelopmentWorkPermit_whatToDo_thirdOption'
		},
		{
			label:
				pageAssets?.request_form_landDevelopmentWorkPermit_whatToDo_fourthOption,
			value:
				REQUEST_CONFIGS_KEYS.whatToDoLandDevelopmentWorkPermit.fourthOption,
			key: 'request_form_landDevelopmentWorkPermit_whatToDo_fourthOption'
		},
		{
			label:
				pageAssets?.request_form_landDevelopmentWorkPermit_whatToDo_fifthOption,
			value: REQUEST_CONFIGS_KEYS.whatToDoLandDevelopmentWorkPermit.fifthOption,
			key: 'request_form_landDevelopmentWorkPermit_whatToDo_fifthOption'
		}
	]

	// Q2 Vous faites cette demande en tant que:
	const [requestAsInput, setRequestAsInput] = useState<RequestInputs>({
		name: 'requestAs',
		label: pageAssets?.request_form_request_as,
		labelKey: 'request_form_request_as',
		value: answers?.requestAs?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	const radioListRequestAs: RadioList[] = [
		{
			label: pageAssets?.request_form_individual,
			value: REQUEST_CONFIGS_KEYS.requestAs.individual,
			key: 'request_form_individual'
		},
		{
			label: pageAssets?.request_form_entreprise,
			value: REQUEST_CONFIGS_KEYS.requestAs.entreprise,
			key: 'request_form_entreprise'
		}, //Entrepreneur or manager
		{
			label: pageAssets?.request_form_entrepreneur_or_manager,
			value: REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager,
			key: 'request_form_entrepreneur_or_manager'
		}
	]

	// ************* Informations relatives à l'entreprise ***********

	// Nom de l'entreprise
	const [companyNameInput, setCompanyNameInput] = useState<RequestInputs>({
		name: 'companyName',
		label:
			pageAssets?.request_form_annualRegistrationCertificateForContractors_companyName_label,
		labelKey:
			'request_form_annualRegistrationCertificateForContractors_companyName_label',
		value: answers.companyName || '',
		required: [
			REQUEST_CONFIGS_KEYS.requestAs.entreprise,
			REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager
		].includes(answers.requestAs?.toString() || ''),
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	// Nom et prénom du représentant
	const [representativeNameInput, setRepresentativeNameInput] =
		useState<RequestInputs>({
			name: 'representativeName',
			label:
				pageAssets?.request_form_swimmingPoolSpaPermit_representativeName_label,
			labelKey: 'request_form_swimmingPoolSpaPermit_representativeName_label',
			value: answers.representativeName || '',
			required: [
				REQUEST_CONFIGS_KEYS.requestAs.entreprise,
				REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager
			].includes(answers.requestAs?.toString() || ''),
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	// Plan d'implantation et d'intégration architecturale (PIIA)

	// Est-ce que les travaux sont assujettis à une approbation PIIA?
	// Is the work subject to PIIA approval?
	const [isSubjectToPiiaApprovalInput, setIsSubjectToPiiaApprovalInput] =
		useState<RequestInputs>({
			name: 'isSubjectToPiiaApproval',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSubjectToPiiaApproval_label,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_isSubjectToPiiaApproval_label',
			description:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSubjectToPiiaApproval_description,
			value: answers.isSubjectToPiiaApproval?.toString() || '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	// Le projet a-t-il reçu une approbation PIIA?
	// Has the project received PIIA approval?
	const [hasBeenSubjectToApprovalInput, setHasBeenSubjectToApprovalInput] =
		useState<RequestInputs>({
			name: 'hasBeenSubjectToApproval',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_hasBeenSubjectToApproval_label,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_hasBeenSubjectToApproval_label',
			value: answers.hasBeenSubjectToApproval?.toString() || '',
			required:
				answers.isSubjectToPiiaApproval?.toString() == radioListYesNo[0].value,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	// 	IA number (architectural integration)
	const [iaNumberInput, setIaNumberInput] = useState<RequestInputs>({
		name: 'iaNumber',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_iaNumber_label,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_iaNumber_label',
		value: answers.iaNumber || '',
		required:
			answers.hasBeenSubjectToApproval?.toString() == radioListYesNo[0].value &&
			answers.isSubjectToPiiaApproval?.toString() == radioListYesNo[0].value,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	// Executive Committee (EC) resolution number
	const [ecResolutionNumberInput, setEcResolutionNumberInput] =
		useState<RequestInputs>({
			name: 'ecResolutionNumber',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_ecResolutionNumber_label,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_ecResolutionNumber_label',
			value: answers.ecResolutionNumber || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	//----------------------------------------------------------------
	//-------------------- SubStep DETAILS_2 INPUTS ------------------
	//----------------------------------------------------------------

	const [isAddressOutsideLaval, setIsAddressOutsideLaval] =
		useState<boolean>(false)

	// hidden input cadastre
	const [buildingBatchNumberInput, setBuildingBatchNumberInput] =
		useState<RequestInputs>({
			name: 'buildingBatchNumber',
			label: '',
			labelKey: '',
			value: answers.buildingBatchNumber || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [isSameLocationInput, setIsSameLocationInput] = useState<RequestInputs>(
		{
			name: 'buildingAddressSameHasApplicant',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSameLocation,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_isSameLocation',
			value: answers?.buildingAddressSameHasApplicant?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		}
	)

	const radioListAddressOption: RadioList[] = [
		{
			label:
				pageAssets.request_form_addressInfoSection_addressOption_secondOption_label,
			value: REQUEST_CONFIGS_KEYS.researchType.cadastralNumber,
			key: 'request_form_addressInfoSection_addressOption_secondOption_label'
		},
		{
			label:
				pageAssets.request_form_addressInfoSection_addressOption_firstOption_label,
			value: REQUEST_CONFIGS_KEYS.researchType.address,
			key: 'request_form_addressInfoSection_addressOption_firstOption_label'
		}
	]

	const [isLocationOwnedInput, setIsLocationOwnedInput] =
		useState<RequestInputs>({
			name: 'isLocationOwned',
			label: pageAssets?.request_form_swimmingPoolSpaPermit_isLocationOwned,
			labelKey: 'request_form_swimmingPoolSpaPermit_isLocationOwned',
			value: answers?.isLocationOwned?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	//****** Owner inputs ******

	const [ownerFirstNameInput, setOwnerFirstNameInput] = useState<RequestInputs>(
		{
			name: 'ownerFirstName',
			label: pageAssets?.label_firstName,
			labelKey: 'label_firstName',
			value: answers.ownerFirstName || '',
			required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		}
	)
	const [ownerLastNameInput, setOwnerLastNameInput] = useState<RequestInputs>({
		name: 'ownerLastName',
		label: pageAssets?.label_lastName,
		labelKey: 'label_lastName',
		value: answers.ownerLastName || '',
		required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	const [ownerEmailInput, setOwnerEmailInput] = useState<RequestInputs>({
		name: 'ownerEmail',
		label: pageAssets?.label_email,
		labelKey: 'label_email',
		value: answers.ownerEmail || '',
		required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	const [ownerPhoneNumberInput, setOwnerPhoneNumberInput] =
		useState<RequestInputs>({
			name: 'ownerPhoneNumber',
			label: pageAssets?.label_telephone,
			labelKey: 'label_telephone',
			value: answers.ownerPhoneNumber || '',
			required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	// Procuration du propriétaire
	// Owner's power of attorney
	const [firstQuestion, setFirstQuestion] = useState<RequestInputFiles>({
		name: 'firstInput',
		label: pageAssets?.request_form_ownerPowerOfAttorney,
		labelKey: 'request_form_ownerPowerOfAttorney',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'firstInput')?.files || []
		),
		description: '',
		required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
		numberMaxFiles: 1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
		category: requestCategories.proxy,
		subCategory: requestSubCategories.subCateg2
	})

	////////////////////////////////////////////////////////////////
	//----------------------------------------------------------------
	//------------------- SubStep DETAILS_3 INPUTS -------------------
	//---------------------------------------------------------------

	//  "Quel est l'usage du bâtiment principal existant?"
	// Question 8
	const [mainUseOfExistingBuildingInput, setMainUseOfExistingBuildingInput] =
		useState<RequestInputs>({
			name: 'mainUseOfExistingBuilding',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding',
			value: answers?.mainUseOfExistingBuilding?.toString() || '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	const radioListMainUseOfExistingBuilding: RadioList[] =
		pageUtils.radioListMainUseOfExistingBuildingLandDevWorkPermit(pageAssets)

	// Les travaux s'appliquent-ils à un usage résidentiel ou autre que résidentiel (industriel, commercial ou institutionnel)?
	const [isResidentialOrOtherInput, setIsResidentialOrOtherInput] =
		useState<RequestInputs>({
			name: 'isResidentialOrOther',
			label: pageAssets?.request_form_landDevWorkPermit_isResidentialOrOther,
			labelKey: 'request_form_landDevWorkPermit_isResidentialOrOther',
			value: answers?.isResidentialOrOther?.toString() || '',
			required:
				answers?.mainUseOfExistingBuilding?.toString() ==
				REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.fourthOption,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	const radioListResidentialOrOther: RadioList[] = [
		{
			label:
				pageAssets?.request_form_landDevWorkPermit_isResidentialOrOther_option1,
			value: REQUEST_CONFIGS_KEYS.isResidentialOrOther.firstOption,
			key: 'request_form_landDevWorkPermit_isResidentialOrOther_option1'
		},
		{
			label:
				pageAssets?.request_form_landDevWorkPermit_isResidentialOrOther_option2,
			value: REQUEST_CONFIGS_KEYS.isResidentialOrOther.secondOption,
			key: 'request_form_landDevWorkPermit_isResidentialOrOther_option2'
		}
	]

	// Quel est le type de structure?
	const [structureTypeInput, setStructureTypeInput] = useState<RequestInputs>({
		name: 'structureType',
		label:
			pageAssets?.request_form_buildingDemolitionOrMovingPermit_structureType,
		labelKey: 'request_form_buildingDemolitionOrMovingPermit_structureType',
		value: answers?.structureType?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	const radioListStructureType: RadioList[] =
		pageUtils.radioListStructureType(pageAssets)

	// Le bâtiment principal existant est-il ou fait-il partie d'une copropriété?
	const [
		isTheNewBuildingPartOfAPropertyInput,
		setIsTheNewBuildingPartOfAPropertyInput
	] = useState<RequestInputs>({
		name: 'existingMainBuildingIsShared',
		label:
			pageAssets?.request_form_buildingDemolitionOrMovingPermit_isTheNewBuildingPartOfAProperty,
		labelKey:
			'request_form_buildingDemolitionOrMovingPermit_isTheNewBuildingPartOfAProperty',
		value: answers?.existingMainBuildingIsShared?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	// Autorisation des administrateurs
	const [secondQuestion, setSecondQuestion] = useState<RequestInputFiles>({
		name: 'secondInput',
		label:
			pageAssets?.request_form_buildingDemolitionOrMovingPermit_adminAutorisation,
		labelKey: 'request_form_buildingDemolitionOrMovingPermit_adminAutorisation',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'secondInput')?.files || []
		),
		required:
			answers?.existingMainBuildingIsShared?.toString() ==
			radioListYesNo[0].value,
		numberMaxFiles: 1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1
	})

	////////////////////////////////////////////////////////////////
	//----------------------------------------------------------------
	//------------------- SubStep DETAILS_4 INPUTS -------------------
	//---------------------------------------------------------------

	// Quel(s) type(s) de travaux souhaitez-vous effectuer?
	// What type of work would you like to do?
	const [typesOfWorkBalconyInput, setTypesOfWorkBalconyInput] =
		useState<RequestInputs>({
			name: 'typesOfWorkBalcony',
			label:
				pageAssets?.request_form_residentialRenovationOrRepairPermit_typesOfWork,
			labelKey: 'request_form_residentialRenovationOrRepairPermit_typesOfWork',
			value: answers?.typesOfWorkBalcony?.toString() || '',
			required: true, // TODO Update to reflect the correct requirement status
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const radioListTypesOfWorkBalcony: CheckboxList[] =
		pageUtils.radioListTypesOfWorkBalcony(pageAssets)

	const isBalconySectionA = (value) => {
		return value?.includes(REQUEST_CONFIGS_KEYS.typesOfWork.firstOption)
	}

	const isBalconySectionB = (value) => {
		return value?.includes(REQUEST_CONFIGS_KEYS.typesOfWork.secondOption)
	}

	// Quel est le type d'installation?
	const [installationTypeInput, setInstallationTypeInput] =
		useState<RequestInputs>({
			name: 'installationType',
			label: pageAssets?.request_form_swimmingPoolSpaPermit_installationType,
			labelKey: 'request_form_swimmingPoolSpaPermit_installationType',
			value: answers?.installationType?.toString() || '',
			required:
				isBalconySectionA(answers?.typesOfWorkBalcony) &&
				[
					REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.firstOption,
					REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.thirdOption
				].includes(answers?.mainUseOfExistingBuilding || ''),
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const diplayOptionDetails = (image) => {
		return (
			<img className={classes.image} src={image} style={{ width: '280px' }} />
		)
	}

	const installationTypeBalconyList: RadioList[] = [
		{
			label:
				pageAssets?.select_landDevWorkPermit_installTypeBalcony_firstOption,
			value: REQUEST_CONFIGS_KEYS.installationTypeBalcony.firstOption,
			key: 'select_landDevWorkPermit_installTypeBalcony_firstOption',
			extraText:
				pageAssets?.select_landDevWorkPermit_installTypeBalcony_firstOption_extraText,
			details: diplayOptionDetails(amenagementBalcon)
		},
		{
			label:
				pageAssets?.select_landDevWorkPermit_installTypeBalcony_secondOption,
			value: REQUEST_CONFIGS_KEYS.installationTypeBalcony.secondOption,
			key: 'select_landDevWorkPermit_installTypeBalcony_secondOption',
			extraText:
				pageAssets?.select_landDevWorkPermit_installTypeBalcony_secondOption_extraText,
			details: diplayOptionDetails(amenagementGalerie)
		},
		{
			label:
				pageAssets?.select_landDevWorkPermit_installTypeBalcony_thirdOption,
			value: REQUEST_CONFIGS_KEYS.installationTypeBalcony.thirdOption,
			key: 'select_landDevWorkPermit_installTypeBalcony_thirdOption',
			extraText:
				pageAssets?.select_landDevWorkPermit_installTypeBalcony_thirdOption_extraText,
			details: diplayOptionDetails(amenagementperron)
		},
		{
			label:
				pageAssets?.select_landDevWorkPermit_installTypeBalcony_fourthOption,
			value: REQUEST_CONFIGS_KEYS.installationTypeBalcony.fourthOption,
			key: 'select_landDevWorkPermit_installTypeBalcony_fourthOption',
			extraText:
				pageAssets?.select_landDevWorkPermit_installTypeBalcony_fourthOption_extraText,
			details: diplayOptionDetails(amenagementporche)
		}
	]

	// Fondation
	const [foundationTypeBalconyInput, setFoundationTypeBalconyInput] =
		useState<RequestInputs>({
			name: 'foundationTypeBalcony',
			label: pageAssets?.request_form_landDevWorkPermit_foundationTypeBalcony,
			labelKey: 'request_form_landDevWorkPermit_foundationTypeBalcony',
			value: answers?.foundationTypeBalcony?.toString() || '',
			required:
				isBalconySectionA(answers?.typesOfWorkBalcony) &&
				[
					REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.firstOption,
					REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.thirdOption
				].includes(answers?.mainUseOfExistingBuilding || ''),
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const radioListFoundationTypeBalcony: SelectList[] =
		pageUtils.radioListFoundationTypeBalcony(pageAssets)

	// Emplacement
	const [locationBalconyInput, setLocationBalconyInput] =
		useState<RequestInputs>({
			name: 'locationBalcony',
			label: pageAssets?.request_form_location,
			labelKey: 'request_form_location',
			value: answers?.locationBalcony?.toString() || '',
			required: isBalconySectionA(answers?.typesOfWorkBalcony),
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const radioListLocationBalcony: SelectList[] = pageUtils
		.radioListPlaceOnTheTerrain(pageAssets)
		.slice(0, 6)

	// Dimensions *****

	// Measurement unit m or ft
	const radioListMeasurementUnit: RadioList[] = [
		{
			label: pageAssets?.request_form_meters,
			value: REQUEST_CONFIGS_KEYS.measurement_unit.meters_unit,
			key: 'request_form_meters'
		},
		{
			label: pageAssets?.request_form_foot,
			value: REQUEST_CONFIGS_KEYS.measurement_unit.foot_unit,
			key: 'request_form_foot'
		}
	]

	// Measurement unit m² or ft²
	const radioListMeasurement2Unit: RadioList[] = [
		{
			label: pageAssets?.request_form_meters2,
			value: REQUEST_CONFIGS_KEYS.measurement_unit.meters_unit,
			key: 'request_form_meters2'
		},
		{
			label: pageAssets?.request_form_foot2,
			value: REQUEST_CONFIGS_KEYS.measurement_unit.foot_unit,
			key: 'request_form_foot2'
		}
	]

	// Largeur
	const [widthBalconyInput, setWidthBalconyInput] = useState<RequestInputs>({
		name: 'widthBalcony',
		label: pageAssets?.request_form_width,
		labelKey: 'request_form_width',
		value: answers?.widthBalcony?.toString() || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	// width unit
	const [widthUnitBalconyInput, setWidthUnitBalconyInput] =
		useState<RequestInputs>({
			name: 'widthUnitBalcony',
			label: pageAssets?.request_form_width_unit,
			labelKey: 'request_form_width_unit',
			value:
				answers?.widthUnitBalcony?.toString() ||
				REQUEST_CONFIGS_KEYS.measurement_unit.meters_unit,
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Profondeur
	const [depthBalconyInput, setDepthBalconyInput] = useState<RequestInputs>({
		name: 'depthBalcony',
		label: pageAssets?.request_form_buildingTransactionRequest_depth_label,
		labelKey: 'request_form_buildingTransactionRequest_depth_label',
		value: answers?.depthBalcony?.toString() || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	// depth unit
	const [depthUnitBalconyInput, setDepthUnitBalconyInput] =
		useState<RequestInputs>({
			name: 'depthUnitBalcony',
			label: pageAssets?.request_form_depth_unit,
			labelKey: 'request_form_depth_unit',
			value:
				answers?.depthUnitBalcony?.toString() ||
				REQUEST_CONFIGS_KEYS.measurement_unit.meters_unit,
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Hauteur
	const [heightBalconyInput, setHeightBalconyInput] = useState<RequestInputs>({
		name: 'heightBalcony',
		label: pageAssets?.request_form_height,
		labelKey: 'request_form_height',
		value: answers?.heightBalcony?.toString() || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	// height unit
	const [heightUnitBalconyInput, setHeightUnitBalconyInput] =
		useState<RequestInputs>({
			name: 'heightUnitBalcony',
			label: pageAssets?.request_form_height_unit,
			labelKey: 'request_form_height_unit',
			value:
				answers?.heightUnitBalcony?.toString() ||
				REQUEST_CONFIGS_KEYS.measurement_unit.meters_unit,
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Superficie
	const [areaBalconyInput, setAreaBalconyInput] = useState<RequestInputs>({
		name: 'areaBalcony',
		label: pageAssets?.request_form_surfaceArea,
		labelKey: 'request_form_surfaceArea',
		value: answers?.areaBalcony?.toString() || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	// area unit
	const [areaUnitBalconyInput, setAreaUnitBalconyInput] =
		useState<RequestInputs>({
			name: 'areaUnitBalcony',
			label: pageAssets?.request_form_area_unit,
			labelKey: 'request_form_area_unit',
			value:
				answers?.areaUnitBalcony?.toString() ||
				REQUEST_CONFIGS_KEYS.measurement_unit.meters_unit,
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Matériaux *****

	// Structure
	const [structureBalconyInput, setStructureBalconyInput] =
		useState<RequestInputs>({
			name: 'structureBalcony',
			label: pageAssets?.request_form_structure,
			labelKey: 'request_form_structure',
			value: answers?.structureBalcony?.toString() || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	//Toit
	const [roofBalconyInput, setRoofBalconyInput] = useState<RequestInputs>({
		name: 'roofBalcony',
		label: pageAssets?.request_form_roof,
		labelKey: 'request_form_roof',
		value: answers?.roofBalcony?.toString() || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	// ----------------------------------------------------------------

	// FondationTerrace
	const [foundationTypeTerraceInput, setFoundationTypeTerraceInput] =
		useState<RequestInputs>({
			name: 'foundationTypeTerrace',
			label: pageAssets?.request_form_landDevWorkPermit_foundationTypeBalcony,
			labelKey: 'request_form_landDevWorkPermit_foundationTypeBalcony',
			value: answers?.foundationTypeTerrace?.toString() || '',
			required: isBalconySectionB(typesOfWorkBalconyInput.value),
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const radioListFoundationTypeTerrace: SelectList[] = [
		...pageUtils.radioListFoundationTypeBalcony(pageAssets).slice(0, 3),
		{
			label: pageAssets?.request_form_select_label_other,
			value: REQUEST_CONFIGS_KEYS.fondationTypeBalcony.thirdOption,
			key: 'request_form_select_label_other'
		}
	]

	const radioListLocationTerrace: SelectList[] = [
		...pageUtils.radioListPlaceOnTheTerrain(pageAssets).slice(0, 6),
		{
			label: pageAssets?.request_form_select_label_other,
			value: REQUEST_CONFIGS_KEYS.placeOnTheTerrain.sixthOption,
			key: 'request_form_select_label_other'
		}
	]

	// EmplacementTerrace
	const [locationTerraceInput, setLocationTerraceInput] =
		useState<RequestInputs>({
			name: 'locationTerrace',
			label: pageAssets?.request_form_location,
			labelKey: 'request_form_location',
			value: answers?.locationTerrace?.toString() || '',
			required: isBalconySectionB(typesOfWorkBalconyInput.value),
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// La terrasse comprend-elle...
	const [isTerraceIncludeInput, setIsTerraceIncludeInput] =
		useState<RequestInputs>({
			name: 'isTerraceInclude',
			label: pageAssets?.request_form_landDevWorkPermit_isTerraceInclude,
			labelKey: 'request_form_landDevWorkPermit_isTerraceInclude',
			value: answers?.isTerraceInclude?.toString() || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const radioListIsTerraceInclude: CheckboxList[] =
		pageUtils.radioListIsTerraceInclude(pageAssets)

	// Dimensions *****
	// Hauteur
	const [heightTerraceInput, setHeightTerraceInput] = useState<RequestInputs>({
		name: 'heightTerrace',
		label: pageAssets?.request_form_height,
		labelKey: 'request_form_height',
		value: answers?.heightTerrace?.toString() || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4,
		description: pageAssets?.request_form_heightTerrace_description
	})

	// height unit
	const [heightUnitTerraceInput, setHeightUnitTerraceInput] =
		useState<RequestInputs>({
			name: 'heightUnitTerrace',
			label: pageAssets?.request_form_height_unit,
			labelKey: 'request_form_height_unit',
			value:
				answers?.heightUnitTerrace?.toString() ||
				REQUEST_CONFIGS_KEYS.measurement_unit.meters_unit,
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Superficie
	const [areaTerraceInput, setAreaTerraceInput] = useState<RequestInputs>({
		name: 'areaTerrace',
		label: pageAssets?.request_form_surfaceArea,
		labelKey: 'request_form_surfaceArea',
		value: answers?.areaTerrace?.toString() || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	// Superficie unit
	const [areaUnitTerraceInput, setAreaUnitTerraceInput] =
		useState<RequestInputs>({
			name: 'areaUnitTerrace',
			label: pageAssets?.request_form_area_unit,
			labelKey: 'request_form_area_unit',
			value:
				answers?.areaUnitTerrace?.toString() ||
				REQUEST_CONFIGS_KEYS.measurement_unit.meters_unit,
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Murs (revêtement extérieur)
	const [wallsTerraceInput, setWallsTerraceInput] = useState<RequestInputs>({
		name: 'wallsTerrace',
		label: pageAssets?.request_form_wallsTerrace,
		labelKey: 'request_form_wallsTerrace',
		value: answers?.wallsTerrace?.toString() || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	// StructureTerrace
	const [structureTerraceInput, setStructureTerraceInput] =
		useState<RequestInputs>({
			name: 'structureTerrace',
			label: pageAssets?.request_form_structure,
			labelKey: 'request_form_structure',
			value: answers?.structureTerrace?.toString() || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// ToitTerrace
	const [roofTerraceInput, setRoofTerraceInput] = useState<RequestInputs>({
		name: 'roofTerrace',
		label: pageAssets?.request_form_roof,
		labelKey: 'request_form_roof',
		value: answers?.roofTerrace?.toString() || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	// ----------------------------------------------------------------
	//-------------------- Informations sur les travaux INPUTS ------------------------

	// -------------------  DETAILS_5 -------------------

	// Effectuez-vous ces travaux à la suite d'une catastrophe?
	const [Q24IsWorkDueToDisasterInput, setQ24IsWorkDueToDisasterInput] =
		useState<RequestInputs>({
			name: 'isWorkDueToDisaster',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isWorkDueToDisaster,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_isWorkDueToDisaster',
			value: answers?.isWorkDueToDisaster?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Veuillez préciser WorkDueToDisasterPrecision
	const [
		Q24_1WorkDueToDisasterPrecisionInput,
		setQ24_1WorkDueToDisasterPrecisionInput
	] = useState<RequestInputs>({
		name: 'workDueToDisasterPrecision',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_workDueToDisasterPrecision,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_workDueToDisasterPrecision',
		value: answers?.workDueToDisasterPrecision?.toString() || '',
		required: Q24IsWorkDueToDisasterInput.value == radioListYesNo[0].value,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Coût approximatif des travaux
	const [Q25ApproximateCostOfWorkInput, setQ25ApproximateCostOfWorkInput] =
		useState<RequestInputs>({
			name: 'approximateCostOfWork',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_approximateCostOfWork,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_approximateCostOfWork',
			value: answers?.approximateCostOfWork?.toString() || '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Est-ce qu'un abattage d'arbres est requis?
	const [Q27IsTreeCuttingRequiredInput, setQ27IsTreeCuttingRequiredInput] =
		useState<RequestInputs>({
			name: 'isTreeCuttingRequired',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTreeCuttingRequired,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_isTreeCuttingRequired',
			description:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTreeCuttingRequired_description,
			value: answers?.isTreeCuttingRequired?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Renseignements supplémentaires
	const [Q28AdditionalInfoInput, setQ28AdditionalInfoInput] =
		useState<RequestInputs>({
			name: 'additionalInfoQ28',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ28,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_additionalInfoQ28',
			description:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ28_description,
			value: answers?.additionalInfoQ28?.toString() || '',
			required: true,
			ref: useRef<HTMLTextAreaElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Date de début (projetée)
	const [Q29StartDateInput, setQ29StartDateInput] = useState<RequestInputs>({
		name: 'workStartDate',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_startDate,
		labelKey: 'request_form_buildingConstructionOrAdditionPermit_startDate',
		value: answers.workStartDate || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Date de fin (projetée)
	const [Q30EndDateInput, setQ30EndDateInput] = useState<RequestInputs>({
		name: 'workEndDate',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_endDate,
		labelKey: 'request_form_buildingConstructionOrAdditionPermit_endDate',
		value: answers.workEndDate || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Entrepreneur exécutant les travaux de construction.

	// Qui sera en charge des travaux?
	const [Q31WhoWillBeInChargeOfWorkInput, setQ31WhoWillBeInChargeOfWorkInput] =
		useState<RequestInputs>({
			name: 'whoWillBeInChargeOfWork',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_whoWillBeInChargeOfWork,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_whoWillBeInChargeOfWork',
			value: answers?.whoWillBeInChargeOfWork?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	const radioListWhoWillBeInChargeOfWork: RadioList[] =
		pageUtils.radioListWhoWillBeInChargeOfWork(pageAssets)

	// Je ne connais pas encore l'entrepreneur
	const [Q31_1EntrepreneurUnknownInput, setQ31_1EntrepreneurUnknownInput] =
		useState<RequestInputs>({
			name: 'entrepreneurUnknown1',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneurUnknown,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_entrepreneurUnknown',
			value: answers.entrepreneurUnknown1?.toString() || String(false),
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		})

	// Nom de l'entreprise exécutant les travaux de construction.
	const [
		Q32CompanyNameExecutingWorkInput,
		setQ32CompanyNameExecutingWorkInput
	] = useState<RequestInputs>({
		name: 'companyNameExecutingWork',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyNameExecutingWork,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_companyNameExecutingWork',
		value: answers.companyNameExecutingWork || '',
		required:
			answers?.whoWillBeInChargeOfWork ==
				radioListWhoWillBeInChargeOfWork[0].value ||
			answers.entrepreneurUnknown1 == true
				? false
				: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Numéro d'entreprise du Québec (NEQ)
	const [Q33CompanyNEQNumberInput, setQ33CompanyNEQNumberInput] =
		useState<RequestInputs>({
			name: 'companyNEQNumber',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyNEQNumber,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_companyNEQNumber',
			value: answers.companyNEQNumber || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Numéro de licence RBQ
	const [Q34CompanyRBQNumberInput, setQ34CompanyRBQNumberInput] =
		useState<RequestInputs>({
			name: 'companyRBQNumber',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRBQNumber,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_companyRBQNumber',
			value: answers.companyRBQNumber || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Nom complet du (de la) représentant(e) ou de la personne contact
	const [
		Q35CompanyRepresentativeNameInput,
		setQ35CompanyRepresentativeNameInput
	] = useState<RequestInputs>({
		name: 'companyRepresentativeName',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRepresentativeName,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_companyRepresentativeName',
		value: answers.companyRepresentativeName || '',
		required:
			answers?.whoWillBeInChargeOfWork ==
				radioListWhoWillBeInChargeOfWork[0].value ||
			answers.entrepreneurUnknown1 == true
				? false
				: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Téléphone
	const [
		Q36CompanyRepresentativePhoneNumberInput,
		setQ36CompanyRepresentativePhoneNumberInput
	] = useState<RequestInputs>({
		name: 'companyRepresentativePhoneNumber',
		label: pageAssets?.label_telephone,
		labelKey: 'label_telephone',
		value: answers.companyRepresentativePhoneNumber || '',
		required:
			answers?.whoWillBeInChargeOfWork ==
				radioListWhoWillBeInChargeOfWork[0].value ||
			answers.entrepreneurUnknown1 == true
				? false
				: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Courriel
	const [
		Q37CompanyRepresentativeEmailInput,
		setQ37CompanyRepresentativeEmailInput
	] = useState<RequestInputs>({
		name: 'companyRepresentativeEmail',
		label: pageAssets?.label_email,
		labelKey: 'label_email',
		value: answers.companyRepresentativeEmail || '',
		required:
			answers?.whoWillBeInChargeOfWork ==
				radioListWhoWillBeInChargeOfWork[0].value ||
			answers.entrepreneurUnknown1 == true
				? false
				: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Adresse de l'entreprise
	const [Q38CompanyAddressInput, setQ38CompanyAddressInput] =
		useState<RequestInputs>({
			name: 'companyAddress',
			label: pageAssets?.label_address,
			labelKey: 'label_address',
			value: answers.companyAddress || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Ville
	const [Q39CompanyCityInput, setQ39CompanyCityInput] = useState<RequestInputs>(
		{
			name: 'companyCity',
			label: pageAssets?.label_city,
			labelKey: 'label_city',
			value: answers.companyCity || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		}
	)

	// Code postal
	const [Q40CompanyPostalCodeInput, setQ40CompanyPostalCodeInput] =
		useState<RequestInputs>({
			name: 'companyPostalCode',
			label: pageAssets?.label_postalCode,
			labelKey: 'label_postalCode',
			value: answers.companyPostalCode || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// ----------------------------------------------------------

	const [dummyOptionalInput, setDummyOptionalInput] = useState<RequestInputs>({
		name: 'dummyOptional',
		label: '',
		labelKey: '',
		value: '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})
	//----------------------------------------------------------------
	//----------------------- DOCUMENTS INPUTS -----------------------
	//----------------------------------------------------------------

	// check if isIA
	// to use value from summary answers or from inputForm.value
	const isPIIACategPermitDocument = (isformInputs?: boolean) => {
		if (isformInputs == true) {
			return isSubjectToPiiaApprovalInput.value == 'true' &&
				hasBeenSubjectToApprovalInput.value == 'true'
				? 'isPIIAApprovalReceived'
				: isSubjectToPiiaApprovalInput.value == 'true' &&
				  hasBeenSubjectToApprovalInput.value == 'false'
				? 'isPIIAApprovalNotReceived'
				: 'isNotPIIA'
		}

		return answers?.isSubjectToPiiaApproval?.toString() == 'true' &&
			answers?.hasBeenSubjectToApproval?.toString() == 'true'
			? 'isPIIAApprovalReceived'
			: answers?.isSubjectToPiiaApproval?.toString() == 'true' &&
			  answers?.hasBeenSubjectToApproval?.toString() == 'false'
			? 'isPIIAApprovalNotReceived'
			: 'isNotPIIA'
	}

	const resolveDocumentCasStatus = ({
		whatToDo,
		isformInputs
	}: {
		whatToDo: string
		isformInputs?: boolean
	}) => {
		if (isBalconyOrPorchPermit(whatToDo)) {
			let mainUseOfExistingBuilding =
				isformInputs == true
					? mainUseOfExistingBuildingInput.value
					: answers?.mainUseOfExistingBuilding?.toString()
			let isResidentialOrOther =
				isformInputs == true
					? isResidentialOrOtherInput.value
					: answers?.isResidentialOrOther?.toString()
			let typesOfWorkBalcony =
				isformInputs == true
					? typesOfWorkBalconyInput.value
					: answers?.typesOfWorkBalcony?.toString()

			if (
				isPIIACategPermitDocument(isformInputs) == 'isPIIAApprovalNotReceived'
			) {
				return documentStatusConfig.landDevWorkPermit['BALCONY']['CAS_4']
			}

			if (mainUseOfExistingBuilding == '') {
				console.log('mainUseOfExistingBuilding is empty')
				return documentStatusConfig.landDevWorkPermit['BALCONY']['CAS_2']
			}

			if (
				isPIIACategPermitDocument(isformInputs) == 'isPIIAApprovalReceived' ||
				isPIIACategPermitDocument(isformInputs) == 'isNotPIIA'
			) {
				switch (mainUseOfExistingBuilding) {
					// Si Q8= Habitation unifamiliale ou Maison mobile
					case REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.firstOption:
					case REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.thirdOption:
						// Si Q11= Ajout d'un balcon, d'une galerie, d'un perron ou d'un porche
						if (
							typesOfWorkBalcony == REQUEST_CONFIGS_KEYS.typesOfWork.firstOption
						) {
							return documentStatusConfig.landDevWorkPermit['BALCONY']['CAS_1']
						}

						// Si Q11= Aménagement d'une terrasse
						else if (
							typesOfWorkBalcony ==
							REQUEST_CONFIGS_KEYS.typesOfWork.secondOption
						) {
							return documentStatusConfig.landDevWorkPermit['BALCONY']['CAS_2']
						}

						break
					// Q8= Bâtiment industriel, commercial, institutionnel ou mixte
					case REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.fourthOption:
						// 8.1= Si usage autre que résidentiel
						if (
							isResidentialOrOther ==
							REQUEST_CONFIGS_KEYS.isResidentialOrOther.firstOption
						) {
							return documentStatusConfig.landDevWorkPermit['BALCONY']['CAS_3']
							// 8.1= usage résidentiel
						} else if (
							isResidentialOrOther ==
							REQUEST_CONFIGS_KEYS.isResidentialOrOther.secondOption
						) {
							return documentStatusConfig.landDevWorkPermit['BALCONY']['CAS_2']
						}

						break
					// Si Q8= Habitation de 2 logements et plus (duplex, ...)
					case REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.secondOption:
						return documentStatusConfig.landDevWorkPermit['BALCONY']['CAS_2']
						break

					default:
						break
				}
			}
		}

		return documentStatusConfig.landDevWorkPermit['BALCONY']['RESET']
	}

	const getDocumentCategory = (
		type: string,
		category: string,
		isformInputs?: boolean
	) => {
		if (category == '') {
			console.log('category is empty')

			return []
		}

		// cas 1
		if (isPIIACategPermitDocument(isformInputs) == 'isNotPIIA') {
			return documentStatusConfig.buildingDemolitionOrMovingPermit[type][
				'isNotPIIA'
			]
		}

		// cas 2
		if (isPIIACategPermitDocument(isformInputs) == 'isPIIAApprovalReceived') {
			return documentStatusConfig.buildingDemolitionOrMovingPermit[type][
				'isPIIA'
			]['isPIIAApprovalReceived']
		}

		// cas 3
		if (
			isPIIACategPermitDocument(isformInputs) == 'isPIIAApprovalNotReceived'
		) {
			return documentStatusConfig.landDevWorkPermit[type]['isPIIA'][
				'isPIIAApprovalNotReceived'
			]
		}

		return []
	}

	const getDocumentStatus = (
		whatToDo: string,
		document: string,
		isformInputs?: boolean
	): string => {
		const categoryConfig = resolveDocumentCasStatus({ whatToDo, isformInputs })

		if (categoryConfig) {
			return categoryConfig[document] || 'not_required'
		}

		return 'not_required'
	}

	const isDocumentToShow = (
		type: string,
		document: string,
		status: string,
		isformInputs?: boolean
	) => {
		return getDocumentStatus(type, document, isformInputs) == status
	}

	// Plan d'implantation fait par un arpenteur-géomètre pour une mouvelle tour de télécommunication
	const [thirdQuestion, setThirdQuestion] = useState<RequestInputFiles>({
		name: 'thirdInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_implementationPlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_implementationPlan',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_implementationPlan_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'thirdInput')?.files || []
		),
		required: false, //isDemolition(answers?.whatToDo),
		numberMaxFiles: 5,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan d'implantation 	Fait à la main, à partir du certificat de localisation
	const [fourthQuestion, setFourthQuestion] = useState<RequestInputFiles>({
		name: 'fourthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_implementationPlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_implementationPlan',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_implementationPlan2_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'fourthInput')?.files || []
		),
		required: false,
		numberMaxFiles: 5,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan d'architecture préliminaire
	const [fifthQuestion, setFifthQuestion] = useState<RequestInputFiles>({
		name: 'fifthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_preliminaryArchitecturePlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_preliminaryArchitecturePlan',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_preliminaryArchitecturePlan_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'fifthInput')?.files || []
		),
		required: false,
		numberMaxFiles: 1,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan d'aménagement
	const [sixthQuestion, setSixthQuestion] = useState<RequestInputFiles>({
		name: 'sixthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_developmentPlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_developmentPlan',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_developmentPlan_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'sixthInput')?.files || []
		),
		required: false,
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan d'aménagement paysager (préliminaire)
	const [seventhQuestion, setSeventhQuestion] = useState<RequestInputFiles>({
		name: 'seventhInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_landscapePlan,
		labelKey: 'request_form_buildingConstructionOrAdditionPermit_landscapePlan',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_landscapePlan_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'seventhInput')?.files || []
		),
		required: false,
		// getDocumentStatus(
		// 	'MOVING',
		// 	answers?.mainUseOfExistingBuilding,
		// 	'seventhInput'
		// ) == 'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Fiche Bâtiment/Fiche Technique*
	const [eighthQuestion, setEighthQuestion] = useState<RequestInputFiles>({
		name: 'eighthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_buildingSheet,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_buildingSheet,',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'eighthInput')?.files || []
		),
		required: false,
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan d'architecture
	const [ninthQuestion, setNinthQuestion] = useState<RequestInputFiles>({
		name: 'ninthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_architecturePlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_architecturePlan',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_architecturePlan_PA_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'ninthInput')?.files || []
		),
		required: false,
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Certificat de localisation
	const [tenthQuestion, setTenthQuestion] = useState<RequestInputFiles>({
		name: 'tenthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_locationCertificate,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_locationCertificate',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'tenthInput')?.files || []
		),
		required: false,
		// getDocumentStatus(
		// 	'MOVING',
		// 	answers?.mainUseOfExistingBuilding,
		// 	'tenthInput'
		// ) == 'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan de structure
	const [eleventhQuestion, setEleventhQuestion] = useState<RequestInputFiles>({
		name: 'eleventhInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_structuralPlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_structuralPlan',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'eleventhInput')?.files || []
		),
		required: false,
		numberMaxFiles: 10,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Autres documents
	const [twelfthQuestion, setTwelfthQuestion] = useState<RequestInputFiles>({
		name: 'twelfthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_otherDocuments,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_otherDocuments',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_otherDocuments_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'twelfthInput')?.files || []
		),
		required: false,
		numberMaxFiles: 10,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// ------------------- DETAILS INPUTS SETTERS --------------------

	// Reusable function to set input and handle errors
	const handleInputChange = (setInput, input, value, inputName) => {
		setInput({
			...input,
			value
		})

		onFixError(UpdateRequestInputsErrorsArray(errors!, inputName))
	}

	// Reusable hook to update inputs
	const useUpdateInputs = (input) => {
		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs!, input))
		}, [input])
	}

	const updateInputsRequired = (inputs: any[], value: boolean) => {
		inputs.forEach((input) =>
			input.setState({ ...input.state, required: value })
		)
	}

	const setRequiredDemolitionRequestInputs = (value: boolean) => {
		updateInputsRequired(
			[
				// {
				// 	setState: setTypeOfBuildingToDemolishInput,
				// 	state: typeOfBuildingToDemolishInput
				// },
				// {
				// 	setState: setDemolitionTypeInput,
				// 	state: demolitionTypeInput
				// }
			],
			value
		)
	}

	const setRequiredMovingRequestInputs = (value: boolean) => {
		updateInputsRequired(
			[
				// {
				// 	setState: setTypeOfBuildingToMoveInput,
				// 	state: typeOfBuildingToMoveInput
				// },
				// {
				// 	setState: setIsTheBuildingToMoveInput,
				// 	state: isTheBuildingToMoveInput
				// },
				// {
				// 	setState: setIsLocationInLavalInput,
				// 	state: isLocationInLavalInput
				// },
				// {
				// 	setState: setReasonForMovingBuildingInput,
				// 	state: reasonForMovingBuildingInput
				// }
			],
			value
		)
	}

	const updateBalconyRequiredDocuments = (
		whatToDo: string,
		isformInputs?: boolean
	) => {
		const category = resolveDocumentCasStatus({ whatToDo, isformInputs })

		console.log('updateBalconyRequiredDocuments category', category)

		setThirdQuestion({
			...thirdQuestion,
			required: category?.thirdInput == 'required'
		})
		setFourthQuestion({
			...fourthQuestion,
			required: category?.fourthInput == 'required'
		})
		setFifthQuestion({
			...fifthQuestion,
			required: category?.fifthInput == 'required'
		})
		setSixthQuestion({
			...sixthQuestion,
			required: category?.sixthInput == 'required'
		})
		setSeventhQuestion({
			...seventhQuestion,
			required: category?.seventhInput == 'required'
		})
		setEighthQuestion({
			...eighthQuestion,
			required: category?.eighthInput == 'required'
		})
		setNinthQuestion({
			...ninthQuestion,
			required: category?.ninthInput == 'required'
		})
		setTenthQuestion({
			...tenthQuestion,
			required: category?.tenthInput == 'required'
		})
		setEleventhQuestion({
			...eleventhQuestion,
			required: category?.eleventhInput == 'required'
		})
	}

	const onSetWhatToDoInput = (value: string) => {
		setWhatToDoInput({ ...whatToDoInput, value })

		// setRequestTypeTitle(pageAssets?.request_form_information)

		if (
			value ==
			REQUEST_CONFIGS_KEYS.whatToDoBuildingDemolitionOrMovingPermit
				.request_demolition
		) {
			setRequiredDemolitionRequestInputs(true)
			setRequiredMovingRequestInputs(false)

			// files
			// setThirdQuestion({ ...thirdQuestion, required: true })
			// updateMovingRequiredDocuments('')
		}

		// if (
		// 	value ==
		// 	REQUEST_CONFIGS_KEYS.whatToDoBuildingDemolitionOrMovingPermit
		// 		.request_moving
		// ) {
		// 	setRequiredMovingRequestInputs(true)
		// 	setRequiredDemolitionRequestInputs(false)

		// 	// files
		// 	// setThirdQuestion({ ...thirdQuestion, required: false })
		// 	// updateMovingRequiredDocuments(mainUseOfExistingBuildingInput.value, true)
		// }

		onFixError(
			errors.filter(
				(error) =>
					![
						whatToDoInput.name,
						isSubjectToPiiaApprovalInput.name,
						hasBeenSubjectToApprovalInput.name,
						iaNumberInput.name
						// typeOfBuildingToDemolishInput.name,
						// typeOfBuildingToDemolishPrecisionInput.name,
						// demolitionTypeInput.name,
						// typeOfBuildingToMoveInput.name,
						// isTheBuildingToMoveInput.name,
						// isLocationInLavalInput.name,
						// reasonForMovingBuildingInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(whatToDoInput)

	useEffect(() => {
		updateBalconyRequiredDocuments(whatToDoInput.value, true)
	}, [
		whatToDoInput,
		isSubjectToPiiaApprovalInput,
		hasBeenSubjectToApprovalInput,
		mainUseOfExistingBuildingInput,
		isResidentialOrOtherInput,
		typesOfWorkBalconyInput
	])
	//------------------------------------------------------------

	const onSetRequestAsInput = (value: string) => {
		setRequestAsInput({ ...requestAsInput, value })

		if (
			[
				REQUEST_CONFIGS_KEYS.requestAs.entreprise,
				REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager
			].includes(value)
		) {
			setCompanyNameInput({ ...companyNameInput, required: true })
			setRepresentativeNameInput({ ...representativeNameInput, required: true })
		} else {
			setCompanyNameInput({ ...companyNameInput, required: false })
			setRepresentativeNameInput({
				...representativeNameInput,
				required: false
			})
		}

		if (
			value == REQUEST_CONFIGS_KEYS.requestAs.individual &&
			Q31WhoWillBeInChargeOfWorkInput.value ==
				REQUEST_CONFIGS_KEYS.whoWillBeInChargeOfWork.firstOption
		) {
			setQ31WhoWillBeInChargeOfWorkInput({
				...Q31WhoWillBeInChargeOfWorkInput,
				value: ''
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						requestAsInput.name,
						companyNameInput.name,
						representativeNameInput.name,
						Q32CompanyNameExecutingWorkInput.name,
						Q33CompanyNEQNumberInput.name,
						Q34CompanyRBQNumberInput.name,
						Q35CompanyRepresentativeNameInput.name,
						Q36CompanyRepresentativePhoneNumberInput.name,
						Q37CompanyRepresentativeEmailInput.name,
						Q38CompanyAddressInput.name,
						Q39CompanyCityInput.name,
						Q40CompanyPostalCodeInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(requestAsInput)

	//------------------------------------------------------------

	const onSetCompanyNameInput = (value: string) => {
		handleInputChange(
			setCompanyNameInput,
			companyNameInput,
			value,
			companyNameInput.name
		)
	}

	useUpdateInputs(companyNameInput)

	//------------------------------------------------------------
	const onSetRepresentativeNameInput = (value: string) => {
		handleInputChange(
			setRepresentativeNameInput,
			representativeNameInput,
			value,
			representativeNameInput.name
		)
	}

	useUpdateInputs(representativeNameInput)

	//------------------------------------------------------------

	const onSetIsSubjectToPiiaApprovalInput = (value: string) => {
		setIsSubjectToPiiaApprovalInput({ ...isSubjectToPiiaApprovalInput, value })

		if (value == radioListYesNo[0].value) {
			setHasBeenSubjectToApprovalInput({
				...hasBeenSubjectToApprovalInput,
				required: true
			})
			// setIaNumberInput({ ...iaNumberInput, required: true })
		} else {
			setHasBeenSubjectToApprovalInput({
				...hasBeenSubjectToApprovalInput,
				required: false,
				value: ''
			})
			setIaNumberInput({ ...iaNumberInput, required: false, value: '' })
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						isSubjectToPiiaApprovalInput.name,
						hasBeenSubjectToApprovalInput.name,
						iaNumberInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(isSubjectToPiiaApprovalInput)

	//------------------------------------------------------------

	const onSetHasBeenSubjectToApprovalInput = (value: string) => {
		setHasBeenSubjectToApprovalInput({
			...hasBeenSubjectToApprovalInput,
			value
		})

		if (value == radioListYesNo[0].value) {
			setIaNumberInput({ ...iaNumberInput, required: true })
		} else {
			setIaNumberInput({ ...iaNumberInput, required: false })
		}

		onFixError(
			errors.filter(
				(error) =>
					![hasBeenSubjectToApprovalInput.name, iaNumberInput.name].includes(
						error
					)
			)
		)
	}

	useUpdateInputs(hasBeenSubjectToApprovalInput)

	//------------------------------------------------------------

	//------------------------------------------------------------

	const onSetIaNumberInput = (value: string) => {
		handleInputChange(
			setIaNumberInput,
			iaNumberInput,
			value.toLocaleUpperCase(),
			iaNumberInput.name
		)
	}

	useUpdateInputs(iaNumberInput)

	//------------------------------------------------------------

	const onSetEcResolutionNumberInput = (value: string) => {
		handleInputChange(
			setEcResolutionNumberInput,
			ecResolutionNumberInput,
			value.toLocaleUpperCase(),
			ecResolutionNumberInput.name
		)
	}

	useUpdateInputs(ecResolutionNumberInput)

	//------------------------------------------------------------

	useEffect(() => {
		// if (isMoving(whatToDoInput.value)) {
		// 	updateMovingRequiredDocuments(mainUseOfExistingBuildingInput.value, true)
		// } else {
		// 	updateMovingRequiredDocuments('')
		// }
	}, [isSubjectToPiiaApprovalInput, hasBeenSubjectToApprovalInput])

	//------------------------------------------------------------

	const getCadastralNumber = (coordinates: AddressCoordinates) => {
		getAddressByCoordinates(coordinates).then((res) => {
			setBuildingBatchNumberInput({
				...buildingBatchNumberInput,
				value: res?.data?.features?.[0].properties.cadastre
			})
		})
	}

	useEffect(() => {
		const coordinates: AddressCoordinates = new AddressCoordinates()
		coordinates.update(authUser?.profile?.address.coordinates!)

		if (coordinates.validate()) {
			setIsAddressOutsideLaval(false)
			getCadastralNumber(coordinates)
		} else {
			setIsAddressOutsideLaval(true)
		}
	}, [])

	// const isValidAddress = () => {
	// 	const coordinates: AddressCoordinates = new AddressCoordinates()
	// 	coordinates.update(authUser?.profile?.address.coordinates!)

	// 	return coordinates.validate()
	// }

	const onSetIsSameLocationInput = (value: string) => {
		setIsSameLocationInput({ ...isSameLocationInput, value })

		setDisableNextButton && setDisableNextButton(true)

		if (value == radioListYesNo[0].value) {
			if (isAddressOutsideLaval) {
				setDisableNextButton && setDisableNextButton(false)
			}
			setTimeout(() => {
				setShowAdresseBlock(false)
			}, 100)
		} else {
			setTimeout(() => {
				setShowAdresseBlock(true)
			}, 100)
		}

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, isSameLocationInput.name)
		)
	}

	useUpdateInputs(isSameLocationInput)

	useEffect(() => {
		if (
			subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 &&
			isAddressOutsideLaval &&
			isSameLocationInput.value == radioListYesNo[0].value
		) {
			setDisableNextButton && setDisableNextButton(false)
		}
	}, [subSteps?.position])
	//------------------------------------------------------------

	const setRequiredOwnerInfoInputs = (value: boolean) => {
		setOwnerFirstNameInput({ ...ownerFirstNameInput, required: value })
		setOwnerLastNameInput({ ...ownerLastNameInput, required: value })
		setOwnerEmailInput({ ...ownerEmailInput, required: value })
		setOwnerPhoneNumberInput({ ...ownerPhoneNumberInput, required: value })
		setFirstQuestion({ ...firstQuestion, required: value })
	}

	const onSetIsLocationOwnedInput = (value: string) => {
		setIsLocationOwnedInput({ ...isLocationOwnedInput, value })

		if (value == radioListYesNo[1].value) {
			setRequiredOwnerInfoInputs(true)
		} else {
			setRequiredOwnerInfoInputs(false)
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						isLocationOwnedInput.name,
						ownerFirstNameInput.name,
						ownerLastNameInput.name,
						ownerEmailInput.name,
						ownerPhoneNumberInput.name,
						firstQuestion.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(isLocationOwnedInput)
	//------------------------------------------------------------

	const onSetOwnerFirstNameInput = (value: string) => {
		handleInputChange(
			setOwnerFirstNameInput,
			ownerFirstNameInput,
			value,
			ownerFirstNameInput.name
		)
	}

	useUpdateInputs(ownerFirstNameInput)
	//------------------------------------------------------------

	const onSetOwnerLastNameInput = (value: string) => {
		handleInputChange(
			setOwnerLastNameInput,
			ownerLastNameInput,
			value,
			ownerLastNameInput.name
		)
	}

	useUpdateInputs(ownerLastNameInput)

	//------------------------------------------------------------

	const onSetOwnerEmailInput = (value: string) => {
		handleInputChange(
			setOwnerEmailInput,
			ownerEmailInput,
			value,
			ownerEmailInput.name
		)
	}

	useUpdateInputs(ownerEmailInput)
	//------------------------------------------------------------

	const onSetOwnerPhoneNumberInput = (value: string) => {
		handleInputChange(
			setOwnerPhoneNumberInput,
			ownerPhoneNumberInput,
			value,
			ownerPhoneNumberInput.name
		)
	}

	useUpdateInputs(ownerPhoneNumberInput)
	//------------------------------------------------------------

	// ----------------------------------------------------------------
	// -------------------------  Substep 3 ---------------------------
	// ----------------------------------------------------------------

	const onSetMainUseOfExistingBuildingInput = (value: string) => {
		setMainUseOfExistingBuildingInput({
			...mainUseOfExistingBuildingInput,
			value
		})

		// if (isMoving(whatToDoInput.value)) {
		// 	updateMovingRequiredDocuments(value, true)
		// } else {
		// 	updateMovingRequiredDocuments('')
		// }

		onFixError(
			errors.filter(
				(error) =>
					![
						mainUseOfExistingBuildingInput.name
						// Q10_1PAExistingMainBuildingHasHousingInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(mainUseOfExistingBuildingInput)

	useEffect(() => {
		if (
			mainUseOfExistingBuildingInput.value ==
			REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.fourthOption
		) {
			setIsResidentialOrOtherInput({
				...isResidentialOrOtherInput,
				required: true
			})
		} else {
			setIsResidentialOrOtherInput({
				...isResidentialOrOtherInput,
				required: false,
				value: ''
			})
		}
	}, [mainUseOfExistingBuildingInput])

	//------------------------------------------------------------

	const onSetIsResidentialOrOtherInput = (value: string) => {
		handleInputChange(
			setIsResidentialOrOtherInput,
			isResidentialOrOtherInput,
			value,
			isResidentialOrOtherInput.name
		)
	}

	useUpdateInputs(isResidentialOrOtherInput)

	//------------------------------------------------------------

	const onSetStructureTypeInput = (value: string) => {
		handleInputChange(
			setStructureTypeInput,
			structureTypeInput,
			value,
			structureTypeInput.name
		)
	}

	useUpdateInputs(structureTypeInput)

	//------------------------------------------------------------

	const onSetIsTheNewBuildingPartOfAPropertyInput = (value: string) => {
		setIsTheNewBuildingPartOfAPropertyInput({
			...isTheNewBuildingPartOfAPropertyInput,
			value
		})

		if (value == radioListYesNo[0].value) {
			setSecondQuestion({ ...secondQuestion, required: true })
		} else {
			setSecondQuestion({ ...secondQuestion, required: false })
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						isTheNewBuildingPartOfAPropertyInput.name,
						secondQuestion.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(isTheNewBuildingPartOfAPropertyInput)

	//------------------------------------------------------------

	////////////////////////////////////////////////////////////////

	// typesOfWorkBalconyInput
	const onSetTypesOfWorkBalconyInput = (value: string) => {
		let newValue
		if (typesOfWorkBalconyInput.value?.includes(value)) {
			newValue = pageUtils.removeStrFromStrArray(
				value,
				typesOfWorkBalconyInput.value
			)
		} else {
			newValue =
				typesOfWorkBalconyInput.value == ''
					? value
					: typesOfWorkBalconyInput.value + `, ${value}`
		}

		setTypesOfWorkBalconyInput({
			...typesOfWorkBalconyInput,
			value: newValue
		})

		// onFixError(
		// 	errors.filter((error) => ![typesOfWorkBalconyInput.name].includes(error))
		// )
	}

	useUpdateInputs(typesOfWorkBalconyInput)

	useEffect(() => {
		// BALCONY SECTION A
		// Q12
		setInstallationTypeInput({
			...installationTypeInput,
			required:
				isBalconySectionA(typesOfWorkBalconyInput.value) &&
				[
					REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.firstOption,
					REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.thirdOption
				].includes(mainUseOfExistingBuildingInput.value)
		})
		// Q13
		setFoundationTypeBalconyInput({
			...foundationTypeBalconyInput,
			required:
				isBalconySectionA(typesOfWorkBalconyInput.value) &&
				[
					REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.firstOption,
					REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.thirdOption
				].includes(mainUseOfExistingBuildingInput.value)
		})
		// Q14
		setLocationBalconyInput({
			...locationBalconyInput,
			required: isBalconySectionA(typesOfWorkBalconyInput.value)
		})

		// TERRACE SECTION B
		// Q21
		setFoundationTypeTerraceInput({
			...foundationTypeTerraceInput,
			required: isBalconySectionB(typesOfWorkBalconyInput.value)
		})
		// Q22
		setLocationTerraceInput({
			...locationTerraceInput,
			required: isBalconySectionB(typesOfWorkBalconyInput.value)
		})

		// isResidentialOrOtherInput
		// REQUEST_CONFIGS_KEYS.isResidentialOrOther.firstOption,
		onFixError(
			errors.filter(
				(error) =>
					![
						typesOfWorkBalconyInput.name,
						installationTypeInput.name,
						foundationTypeBalconyInput.name,
						locationBalconyInput.name
					].includes(error)
			)
		)
	}, [typesOfWorkBalconyInput, mainUseOfExistingBuildingInput])

	//------------------------------------------------------------
	// installationTypeInput
	const onSetInstallationTypeInput = (value: string) => {
		handleInputChange(
			setInstallationTypeInput,
			installationTypeInput,
			value,
			installationTypeInput.name
		)
	}

	useUpdateInputs(installationTypeInput)

	// ------------------------------------------------------------

	// onSetFoundationTypeBalconyInput
	const onSetFoundationTypeBalconyInput = (value: string) => {
		handleInputChange(
			setFoundationTypeBalconyInput,
			foundationTypeBalconyInput,
			value,
			foundationTypeBalconyInput.name
		)
	}

	useUpdateInputs(foundationTypeBalconyInput)

	// ------------------------------------------------------------
	// onSetLocationBalconyInput
	const onSetLocationBalconyInput = (value: string) => {
		handleInputChange(
			setLocationBalconyInput,
			locationBalconyInput,
			value,
			locationBalconyInput.name
		)
	}

	useUpdateInputs(locationBalconyInput)

	// ------------------------------------------------------------

	// widthBalconyInput
	const onSetWidthBalconyInput = (value: string) => {
		handleInputChange(
			setWidthBalconyInput,
			widthBalconyInput,
			value,
			widthBalconyInput.name
		)
	}

	useUpdateInputs(widthBalconyInput)

	// ------------------------------------------------------------

	// widthUnitBalconyInput
	const onSetWidthUnitBalconyInput = (value: string) => {
		handleInputChange(
			setWidthUnitBalconyInput,
			widthUnitBalconyInput,
			value,
			widthUnitBalconyInput.name
		)
	}

	useUpdateInputs(widthUnitBalconyInput)

	// ------------------------------------------------------------

	// depthBalconyInput
	const onSetDepthBalconyInput = (value: string) => {
		handleInputChange(
			setDepthBalconyInput,
			depthBalconyInput,
			value,
			depthBalconyInput.name
		)
	}

	useUpdateInputs(depthBalconyInput)

	// ------------------------------------------------------------

	// depthUnitBalconyInput
	const onSetDepthUnitBalconyInput = (value: string) => {
		handleInputChange(
			setDepthUnitBalconyInput,
			depthUnitBalconyInput,
			value,
			depthUnitBalconyInput.name
		)
	}

	useUpdateInputs(depthUnitBalconyInput)

	// ------------------------------------------------------------

	// heightBalconyInput
	const onSetHeightBalconyInput = (value: string) => {
		handleInputChange(
			setHeightBalconyInput,
			heightBalconyInput,
			value,
			heightBalconyInput.name
		)
	}

	useUpdateInputs(heightBalconyInput)

	// ------------------------------------------------------------
	// heightUnitBalconyInput
	const onSetHeightUnitBalconyInput = (value: string) => {
		handleInputChange(
			setHeightUnitBalconyInput,
			heightUnitBalconyInput,
			value,
			heightUnitBalconyInput.name
		)
	}

	useUpdateInputs(heightUnitBalconyInput)

	// ------------------------------------------------------------
	// areaBalconyInput
	const onSetAreaBalconyInput = (value: string) => {
		handleInputChange(
			setAreaBalconyInput,
			areaBalconyInput,
			value,
			areaBalconyInput.name
		)
	}

	useUpdateInputs(areaBalconyInput)

	// ------------------------------------------------------------

	// areaUnitBalconyInput
	const onSetAreaUnitBalconyInput = (value: string) => {
		handleInputChange(
			setAreaUnitBalconyInput,
			areaUnitBalconyInput,
			value,
			areaUnitBalconyInput.name
		)
	}

	useUpdateInputs(areaUnitBalconyInput)

	// ------------------------------------------------------------

	// structureBalconyInput
	const onSetStructureBalconyInput = (value: string) => {
		handleInputChange(
			setStructureBalconyInput,
			structureBalconyInput,
			value,
			structureBalconyInput.name
		)
	}

	useUpdateInputs(structureBalconyInput)

	// ------------------------------------------------------------

	const onSetRoofBalconyInput = (value: string) => {
		handleInputChange(
			setRoofBalconyInput,
			roofBalconyInput,
			value,
			roofBalconyInput.name
		)
	}

	useUpdateInputs(roofBalconyInput)

	// ------------------------------------------------------------
	// ------------------------------------------------------------
	// foundationTypeTerraceInput
	const onSetFoundationTypeTerraceInput = (value: string) => {
		handleInputChange(
			setFoundationTypeTerraceInput,
			foundationTypeTerraceInput,
			value,
			foundationTypeTerraceInput.name
		)
	}

	useUpdateInputs(foundationTypeTerraceInput)

	// ------------------------------------------------------------

	// locationTerraceInput
	const onSetLocationTerraceInput = (value: string) => {
		handleInputChange(
			setLocationTerraceInput,
			locationTerraceInput,
			value,
			locationTerraceInput.name
		)
	}

	useUpdateInputs(locationTerraceInput)

	// ------------------------------------------------------------

	// isTerraceIncludeInput
	const onSetIsTerraceIncludeInput = (value: string) => {
		let newValue
		if (isTerraceIncludeInput.value?.includes(value)) {
			newValue = pageUtils.removeStrFromStrArray(
				value,
				isTerraceIncludeInput.value
			)
		} else {
			newValue =
				isTerraceIncludeInput.value == ''
					? value
					: isTerraceIncludeInput.value + `, ${value}`
		}

		setIsTerraceIncludeInput({
			...isTerraceIncludeInput,
			value: newValue
		})

		onFixError(
			errors.filter((error) => ![isTerraceIncludeInput.name].includes(error))
		)
	}

	useUpdateInputs(isTerraceIncludeInput)

	// ------------------------------------------------------------
	// heightTerraceInput
	const onSetHeightTerraceInput = (value: string) => {
		handleInputChange(
			setHeightTerraceInput,
			heightTerraceInput,
			value,
			heightTerraceInput.name
		)
	}

	useUpdateInputs(heightTerraceInput)

	// ------------------------------------------------------------
	// heightUnitTerraceInput
	const onSetHeightUnitTerraceInput = (value: string) => {
		handleInputChange(
			setHeightUnitTerraceInput,
			heightUnitTerraceInput,
			value,
			heightUnitTerraceInput.name
		)
	}

	useUpdateInputs(heightUnitTerraceInput)

	// ------------------------------------------------------------

	// areaTerraceInput
	const onSetAreaTerraceInput = (value: string) => {
		handleInputChange(
			setAreaTerraceInput,
			areaTerraceInput,
			value,
			areaTerraceInput.name
		)
	}

	useUpdateInputs(areaTerraceInput)

	// ------------------------------------------------------------
	// areaUnitTerraceInput
	const onSetAreaUnitTerraceInput = (value: string) => {
		handleInputChange(
			setAreaUnitTerraceInput,
			areaUnitTerraceInput,
			value,
			areaUnitTerraceInput.name
		)
	}

	useUpdateInputs(areaUnitTerraceInput)

	// ------------------------------------------------------------

	// wallsTerraceInput
	const onSetWallsTerraceInput = (value: string) => {
		handleInputChange(
			setWallsTerraceInput,
			wallsTerraceInput,
			value,
			wallsTerraceInput.name
		)
	}

	useUpdateInputs(wallsTerraceInput)

	// ------------------------------------------------------------
	// structureTerraceInput
	const onSetStructureTerraceInput = (value: string) => {
		handleInputChange(
			setStructureTerraceInput,
			structureTerraceInput,
			value,
			structureTerraceInput.name
		)
	}

	useUpdateInputs(structureTerraceInput)

	// ------------------------------------------------------------

	// roofTerraceInput
	const onSetRoofTerraceInput = (value: string) => {
		handleInputChange(
			setRoofTerraceInput,
			roofTerraceInput,
			value,
			roofTerraceInput.name
		)
	}

	useUpdateInputs(roofTerraceInput)

	// ------------------------------------------------------------

	// ------------------------------------------------------------

	// ----------------------------------------------------------------
	// -------------------------  Substep 4 ---------------------------
	// ----------------------------------------------------------------

	//------------------------------------------------------------

	const onSetQ24IsWorkDueToDisasterInput = (value: string) => {
		setQ24IsWorkDueToDisasterInput({
			...Q24IsWorkDueToDisasterInput,
			value
		})

		if (value == radioListYesNo[0].value) {
			setQ24_1WorkDueToDisasterPrecisionInput({
				...Q24_1WorkDueToDisasterPrecisionInput,
				required: true
			})
		} else {
			setQ24_1WorkDueToDisasterPrecisionInput({
				...Q24_1WorkDueToDisasterPrecisionInput,
				required: false,
				value: ''
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						Q24IsWorkDueToDisasterInput.name,
						Q24_1WorkDueToDisasterPrecisionInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(Q24IsWorkDueToDisasterInput)

	//------------------------------------------------------------

	const onSetQ24_1WorkDueToDisasterPrecisionInput = (value: string) => {
		handleInputChange(
			setQ24_1WorkDueToDisasterPrecisionInput,
			Q24_1WorkDueToDisasterPrecisionInput,
			value,
			Q24_1WorkDueToDisasterPrecisionInput.name
		)
	}

	useUpdateInputs(Q24_1WorkDueToDisasterPrecisionInput)

	//------------------------------------------------------------

	const onSetQ25ApproximateCostOfWorkInput = (value) => {
		// const rawValue = value?.toString().replace(/ /g, '')
		handleInputChange(
			setQ25ApproximateCostOfWorkInput,
			Q25ApproximateCostOfWorkInput,
			value,
			Q25ApproximateCostOfWorkInput.name
		)
	}

	useUpdateInputs(Q25ApproximateCostOfWorkInput)

	//------------------------------------------------------------

	//------------------------------------------------------------

	const onSetQ27IsTreeCuttingRequiredInput = (value: string) => {
		handleInputChange(
			setQ27IsTreeCuttingRequiredInput,
			Q27IsTreeCuttingRequiredInput,
			value,
			Q27IsTreeCuttingRequiredInput.name
		)
	}

	useUpdateInputs(Q27IsTreeCuttingRequiredInput)

	//------------------------------------------------------------

	const onSetQ28AdditionalInfoInput = (value: string) => {
		handleInputChange(
			setQ28AdditionalInfoInput,
			Q28AdditionalInfoInput,
			value,
			Q28AdditionalInfoInput.name
		)
	}

	useUpdateInputs(Q28AdditionalInfoInput)

	//------------------------------------------------------------

	const onSetQ29StartDateInput = (value: string) => {
		handleInputChange(
			setQ29StartDateInput,
			Q29StartDateInput,
			value,
			Q29StartDateInput.name
		)
	}

	useUpdateInputs(Q29StartDateInput)

	//------------------------------------------------------------

	const onSetQ30EndDateInput = (value: string) => {
		handleInputChange(
			setQ30EndDateInput,
			Q30EndDateInput,
			value,
			Q30EndDateInput.name
		)
	}

	useUpdateInputs(Q30EndDateInput)

	//------------------------------------------------------------

	const setCompanyInputsRequired = (value: boolean) => {
		setQ32CompanyNameExecutingWorkInput({
			...Q32CompanyNameExecutingWorkInput,
			required: value
		})
		setQ33CompanyNEQNumberInput({
			...Q33CompanyNEQNumberInput,
			required: value
		})
		setQ34CompanyRBQNumberInput({
			...Q34CompanyRBQNumberInput,
			required: value
		})
		setQ35CompanyRepresentativeNameInput({
			...Q35CompanyRepresentativeNameInput,
			required: value
		})
		setQ36CompanyRepresentativePhoneNumberInput({
			...Q36CompanyRepresentativePhoneNumberInput,
			required: value
		})
		setQ37CompanyRepresentativeEmailInput({
			...Q37CompanyRepresentativeEmailInput,
			required: value
		})
		setQ38CompanyAddressInput({
			...Q38CompanyAddressInput,
			required: value
		})
		setQ39CompanyCityInput({
			...Q39CompanyCityInput,
			required: value
		})
		setQ40CompanyPostalCodeInput({
			...Q40CompanyPostalCodeInput,
			required: value
		})
	}

	const onSetQ31WhoWillBeInChargeOfWorkInput = (value: string) => {
		setQ31WhoWillBeInChargeOfWorkInput({
			...Q31WhoWillBeInChargeOfWorkInput,
			value
		})

		setQ31_1EntrepreneurUnknownInput({
			...Q31_1EntrepreneurUnknownInput,
			value: 'false'
		})

		if (value == radioListWhoWillBeInChargeOfWork[0].value) {
			setCompanyInputsRequired(false)
		} else {
			setCompanyInputsRequired(true)
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						Q31WhoWillBeInChargeOfWorkInput.name,
						Q32CompanyNameExecutingWorkInput.name,
						Q33CompanyNEQNumberInput.name,
						Q34CompanyRBQNumberInput.name,
						Q35CompanyRepresentativeNameInput.name,
						Q36CompanyRepresentativePhoneNumberInput.name,
						Q37CompanyRepresentativeEmailInput.name,
						Q38CompanyAddressInput.name,
						Q39CompanyCityInput.name,
						Q40CompanyPostalCodeInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(Q31WhoWillBeInChargeOfWorkInput)

	// --------------------------------------------------------------------------

	const onSetQ31_1EntrepreneurUnknownInput = (value: string) => {
		setQ31_1EntrepreneurUnknownInput({
			...Q31_1EntrepreneurUnknownInput,
			value
		})

		if (value?.toString() == 'true') {
			setCompanyInputsRequired(false)
		} else {
			setCompanyInputsRequired(true)
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						Q32CompanyNameExecutingWorkInput.name,
						Q33CompanyNEQNumberInput.name,
						Q34CompanyRBQNumberInput.name,
						Q35CompanyRepresentativeNameInput.name,
						Q36CompanyRepresentativePhoneNumberInput.name,
						Q37CompanyRepresentativeEmailInput.name,
						Q38CompanyAddressInput.name,
						Q39CompanyCityInput.name,
						Q40CompanyPostalCodeInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(Q31_1EntrepreneurUnknownInput)

	//------------------------------------------------------------

	const onSetQ32CompanyNameExecutingWorkInput = (value: string) => {
		handleInputChange(
			setQ32CompanyNameExecutingWorkInput,
			Q32CompanyNameExecutingWorkInput,
			value,
			Q32CompanyNameExecutingWorkInput.name
		)
	}

	useUpdateInputs(Q32CompanyNameExecutingWorkInput)

	//------------------------------------------------------------

	const onSetQ33CompanyNEQNumberInput = (value: string) => {
		handleInputChange(
			setQ33CompanyNEQNumberInput,
			Q33CompanyNEQNumberInput,
			value,
			Q33CompanyNEQNumberInput.name
		)
	}

	useUpdateInputs(Q33CompanyNEQNumberInput)
	//------------------------------------------------------------

	const onSetQ34CompanyRBQNumberInput = (value: string) => {
		handleInputChange(
			setQ34CompanyRBQNumberInput,
			Q34CompanyRBQNumberInput,
			value,
			Q34CompanyRBQNumberInput.name
		)
	}

	useUpdateInputs(Q34CompanyRBQNumberInput)
	//------------------------------------------------------------

	const onSetQ35CompanyRepresentativeNameInput = (value: string) => {
		handleInputChange(
			setQ35CompanyRepresentativeNameInput,
			Q35CompanyRepresentativeNameInput,
			value,
			Q35CompanyRepresentativeNameInput.name
		)
	}

	useUpdateInputs(Q35CompanyRepresentativeNameInput)
	//------------------------------------------------------------
	const onSetQ36CompanyRepresentativePhoneNumberInput = (value: string) => {
		handleInputChange(
			setQ36CompanyRepresentativePhoneNumberInput,
			Q36CompanyRepresentativePhoneNumberInput,
			value,
			Q36CompanyRepresentativePhoneNumberInput.name
		)
	}

	useUpdateInputs(Q36CompanyRepresentativePhoneNumberInput)
	//------------------------------------------------------------

	const onSetQ37CompanyRepresentativeEmailInput = (value: string) => {
		handleInputChange(
			setQ37CompanyRepresentativeEmailInput,
			Q37CompanyRepresentativeEmailInput,
			value,
			Q37CompanyRepresentativeEmailInput.name
		)
	}

	useUpdateInputs(Q37CompanyRepresentativeEmailInput)
	//------------------------------------------------------------

	const onSetQ38CompanyAddressInput = (value: string) => {
		handleInputChange(
			setQ38CompanyAddressInput,
			Q38CompanyAddressInput,
			value,
			Q38CompanyAddressInput.name
		)
	}

	useUpdateInputs(Q38CompanyAddressInput)
	//------------------------------------------------------------

	const onSetQ39CompanyCityInput = (value: string) => {
		handleInputChange(
			setQ39CompanyCityInput,
			Q39CompanyCityInput,
			value,
			Q39CompanyCityInput.name
		)
	}

	useUpdateInputs(Q39CompanyCityInput)
	//------------------------------------------------------------
	const onSetQ40CompanyPostalCodeInput = (value: string) => {
		handleInputChange(
			setQ40CompanyPostalCodeInput,
			Q40CompanyPostalCodeInput,
			value?.toUpperCase(),
			Q40CompanyPostalCodeInput.name
		)
	}

	useUpdateInputs(Q40CompanyPostalCodeInput)
	//------------------------------------------------------------

	// --------------------------------------------------------------------------

	// --------------------------------------------------------------------------
	// --------------------------------------------------------------------------

	//------------------------------------------------------------

	// --------------------------------------------------------------------------

	//////////////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, dummyOptionalInput))
	}, [dummyOptionalInput])

	////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////
	// DOCUMENTS
	////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////

	const updateQuestionFiles = (setQuestion, question, files) => {
		setQuestion({
			...question,
			files
		})
		onFixError(UpdateRequestInputsErrorsArray(errors, question.name))
	}

	const onSelectFileFirstQuestion = (files: Set<File>) => {
		updateQuestionFiles(setFirstQuestion, firstQuestion, files)
	}

	const onSelectFileSecondQuestion = (files: Set<File>) => {
		updateQuestionFiles(setSecondQuestion, secondQuestion, files)
	}

	const onSelectFileThirdQuestion = (files: Set<File>) => {
		updateQuestionFiles(setThirdQuestion, thirdQuestion, files)
	}

	const onSelectFileFourthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setFourthQuestion, fourthQuestion, files)
	}

	const onSelectFileFifthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setFifthQuestion, fifthQuestion, files)
	}

	const onSelectFileSixthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setSixthQuestion, sixthQuestion, files)
	}

	const onSelectFileSeventhQuestion = (files: Set<File>) => {
		updateQuestionFiles(setSeventhQuestion, seventhQuestion, files)
	}

	const onSelectFileEighthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setEighthQuestion, eighthQuestion, files)
	}

	const onSelectFileNinthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setNinthQuestion, ninthQuestion, files)
	}

	const onSelectFileTenthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setTenthQuestion, tenthQuestion, files)
	}

	const onSelectFileEleventhQuestion = (files: Set<File>) => {
		updateQuestionFiles(setEleventhQuestion, eleventhQuestion, files)
	}

	const onSelectFileTwelfthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setTwelfthQuestion, twelfthQuestion, files)
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////
	// FILES
	////////////////////////////////////////////////////////////////////////////////////////////////

	////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, firstQuestion))
	}, [firstQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, secondQuestion))
	}, [secondQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, thirdQuestion))
	}, [thirdQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, fourthQuestion))
	}, [fourthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, fifthQuestion))
	}, [fifthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, sixthQuestion))
	}, [sixthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, seventhQuestion))
	}, [seventhQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, eighthQuestion))
	}, [eighthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, ninthQuestion))
	}, [ninthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, tenthQuestion))
	}, [tenthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, eleventhQuestion))
	}, [eleventhQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, twelfthQuestion))
	}, [twelfthQuestion])

	////////////////////////////////////////////////////////////////////////////////////////////////
	// FILES FROM STORE

	// Helper function to set question files
	const setQuestionFiles = (inputName: string, question, setQuestion) => {
		if (!!answers[inputName] && question.files.size == 0) {
			getFilesFromStore(inputName, answers).then((result: Set<File>) => {
				setQuestion({
					...question,
					files: result
				})
			})
		}
	}

	useEffect(() => {
		// check if exist answers (filesInputs) at the store, to intialize the input with the files already present
		// ----------------------------
		// firstInput input
		setQuestionFiles('firstInput', firstQuestion, setFirstQuestion)

		// secondInput input
		setQuestionFiles('secondInput', secondQuestion, setSecondQuestion)

		// thirdInput input
		setQuestionFiles('thirdInput', thirdQuestion, setThirdQuestion)

		// fouthInput input
		setQuestionFiles('fourthInput', fourthQuestion, setFourthQuestion)

		// fifthInput input
		setQuestionFiles('fifthInput', fifthQuestion, setFifthQuestion)

		// sixthQuestion input
		setQuestionFiles('sixthInput', sixthQuestion, setSixthQuestion)

		// seventhQuestion input
		setQuestionFiles('seventhInput', seventhQuestion, setSeventhQuestion)

		// eighthQuestion input
		setQuestionFiles('eighthInput', eighthQuestion, setEighthQuestion)

		// ninthQuestion input
		setQuestionFiles('ninthInput', ninthQuestion, setNinthQuestion)

		// tenthQuestion input
		setQuestionFiles('tenthInput', tenthQuestion, setTenthQuestion)

		// eleventhQuestion input
		setQuestionFiles('eleventhInput', eleventhQuestion, setEleventhQuestion)

		// twelfthQuestion input
		setQuestionFiles('twelfthInput', twelfthQuestion, setTwelfthQuestion)
	}, [])

	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}

			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	////////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		// setSteps &&
		// 	setSteps({
		// 		...StepsUrbanism
		// 	})

		onSetSubSteps({
			map: false,
			details_1: true,
			details_2: true,
			details_3: true,
			details_4: true,
			details_5: true,
			details_6: true,
			requiredDocument: true,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5,
				REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
			]
		})

		setStepsList &&
			setStepsList([
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step1),
					subSteps: []
				},
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step2),
					subSteps: []
				},
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step3),
					subSteps: [
						pageAssets?.request_form_type_de_demandes,
						pageAssets?.request_form_request_location,
						pageAssets?.request_form_type_of_use,
						pageAssets?.request_form_land_developement,
						pageAssets?.request_form_information_on_the_work,
						pageAssets?.request_form_required_documents
					]
				},
				// {
				// 	title: formatStrapiText(
				// 		pageAssets?.page_requestInfo_step_complementary_requests
				// 	),
				// 	subSteps: [pageAssets?.page_requestInfo_step_complementary_requests]
				// },
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step4),
					subSteps: []
				},
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step5),
					subSteps: []
				}
			])
	}, [])

	const backToForm = (
		step: number,
		subStep: string,
		currentSubStep: number
	) => {
		if (setCurrentStep && onSetSubSteps && subSteps && onSetCurrentSubStep) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
			onSetCurrentSubStep(currentSubStep)
		}
	}

	const getLabelFromList = (list, value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	const questionAnswer = (question: string, answer?: string) => {
		if (answer && !answer?.includes('undefined')) {
			return (
				<div>
					<strong>{question}</strong>
					<br />
					<span className={pageUtils.classes.answerURB}>{answer}</span>
				</div>
			)
		}
	}

	const getLabelsFromString = (list, answer?: string): string[] => {
		return list
			.filter((element) => answer?.includes(element.value))
			.map((item) => item.label)
	}

	// a function to render RequestFiles on the DOCUMENT or Summary step
	const renderRequestFiles = (
		type: string,
		question: RequestInputFiles,
		onSelectFileFunction,
		status: string,
		isSummary?: boolean
	) => {
		if (isSummary == true) {
			return (
				isDocumentToShow(type, question.name, status) &&
				question.files.size > 0 && (
					<RequestFiles
						subtitle={question.label}
						name={question.name}
						required={question.required}
						files={question.files}
						hasError={errors.includes(question.name)}
						removeSectionMarginTop
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectFileFunction}
						maxFiles={question?.numberMaxFiles}
						isSummary
					/>
				)
			)
		}

		return (
			isDocumentToShow(type, question.name, status, true) && (
				<RequestFiles
					subtitle={question.label}
					name={question.name}
					required={question.required}
					files={question.files}
					hasError={errors.includes(question.name)}
					removeSectionMarginTop
					onSetHasError={onSetErrorFromFileComponent}
					onSetFiles={onSelectFileFunction}
					subSteps={subSteps}
					maxFiles={question?.numberMaxFiles}
					description={question.description}
				/>
			)
		)
	}

	// show the files on the DOCUMENT step
	// @status : the status of the document (required or optionnal)
	// @isSummary : if the step is the summary step
	const renderBalconyRequestFiles = (status: string, isSummary: boolean) => {
		return (
			<>
				{renderRequestFiles(
					whatToDoInput.value,
					thirdQuestion,
					onSelectFileThirdQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					whatToDoInput.value,
					fourthQuestion,
					onSelectFileFourthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					whatToDoInput.value,
					fifthQuestion,
					onSelectFileFifthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					whatToDoInput.value,
					sixthQuestion,
					onSelectFileSixthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					whatToDoInput.value,
					seventhQuestion,
					onSelectFileSeventhQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					whatToDoInput.value,
					eighthQuestion,
					onSelectFileEighthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					whatToDoInput.value,
					ninthQuestion,
					onSelectFileNinthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					whatToDoInput.value,
					tenthQuestion,
					onSelectFileTenthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					whatToDoInput.value,
					eleventhQuestion,
					onSelectFileEleventhQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					whatToDoInput.value,
					twelfthQuestion,
					onSelectFileTwelfthQuestion,
					status,
					isSummary
				)}
			</>
		)
	}

	const handleMeasurementInputChange = (
		value,
		measurementInputOnChange,
		maxCharacters
	) => {
		const cleanedValue = value.replace(/[.,]/g, '')

		if (cleanedValue.length <= maxCharacters) {
			measurementInputOnChange(value)
		}
	}

	const renderMeasurementInput = (
		measurementInput,
		measurementInputOnChange,
		unitInput,
		unitInputOnchange,
		listMeasurementUnit,
		maxCharacters,
		hasThousandsSeparator: boolean = false
	) => {
		return (
			<div className={classes.subContent}>
				{hasThousandsSeparator ? (
					<MaskedThousandsSeparatorInput
						id={measurementInput.name}
						label={measurementInput.label}
						value={measurementInput.value}
						onChange={(value) =>
							handleMeasurementInputChange(
								value,
								measurementInputOnChange,
								maxCharacters
							)
						}
						hasError={errors.includes(measurementInput.name)}
						required={measurementInput.required}
						ref={measurementInput.ref as RefObject<HTMLInputElement>}
						errors={errors}
						setError={onFixError}
						maxCharacters={maxCharacters}
					/>
				) : (
					<TextInput
						id={measurementInput.name}
						type="number"
						label={measurementInput.label}
						value={measurementInput.value ?? ''}
						onChange={(value) =>
							handleMeasurementInputChange(
								value,
								measurementInputOnChange,
								maxCharacters
							)
						}
						hasError={errors?.includes(measurementInput.name)}
						customErrorText={
							measurementInput.value !== ''
								? 'Veuillez entrer un nombre valide.'
								: undefined
						}
						onKeyPress={pageUtils.onKeyPressNumberInput}
						min="0"
						required={measurementInput.required}
						maxCharacters={maxCharacters}
						description={measurementInput.description ?? ''}
					/>
				)}

				<RadioInput
					name={unitInput.name}
					label={''}
					value={unitInput.value ?? ''}
					list={listMeasurementUnit}
					direction={Direction.horizontal}
					hasError={errors!.includes(unitInput.name)}
					onChange={unitInputOnchange}
					hasBackgroundWrapper={true}
				/>
			</div>
		)
	}

	useEffect(() => {
		{
			console.log('Errors -> ', errors)
			// console.table(inputFiles)
		}
	}, [errors])

	if (isSummaryStep) {
		return (
			<section
				className={joinClasses([
					pageUtils.classes.noMargin,
					pageUtils.classes.collapse,
					pageUtils.classes.summarySubTitles,
					toPrint ? pageUtils.classes.toPrintTitle : ''
				])}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<Collapse
							id={1}
							buttonText={pageAssets?.request_form_type_de_demandes}
							isOpen={toPrint}
							key={`request_form_type_de_demandes`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
												1
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_type_de_demandes}
									</h4>
								)}
							</div>

							{/* Question 1 */}
							<div>
								<strong>{whatToDoInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									<Text
										content={getLabelFromList(
											radioListWhatToDo,
											answers?.whatToDo
										)}
									/>
								</span>
							</div>

							{/* Question 2 */}
							<div>
								<strong>{requestAsInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(radioListRequestAs, answers?.requestAs)}
								</span>
							</div>

							{[
								REQUEST_CONFIGS_KEYS.requestAs.entreprise,
								REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager
							].includes(answers?.requestAs?.toString() || '') && (
								<>
									<div>
										<strong>{companyNameInput.label}</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{answers?.companyName}
										</span>
									</div>
									<div>
										<strong>{representativeNameInput.label}</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{answers?.representativeName}
										</span>
									</div>
								</>
							)}

							{isMoving(answers?.whatToDo) && (
								<>
									{/* Question 3: Is Subject to PIIA Approval */}
									<div>
										<strong>{isSubjectToPiiaApprovalInput.label}</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{getLabelFromList(
												radioListYesNo,
												answers?.isSubjectToPiiaApproval
											)}
										</span>
									</div>

									{/* Question 4: Has Been Subject to Approval */}
									{answers?.isSubjectToPiiaApproval?.toString() ==
										radioListYesNo[0].value && (
										<>
											<div>
												<strong>{hasBeenSubjectToApprovalInput.label}</strong>
												<br />
												<span className={pageUtils.classes.answerURB}>
													{getLabelFromList(
														radioListYesNo,
														answers?.hasBeenSubjectToApproval
													)}
												</span>
											</div>

											{answers?.hasBeenSubjectToApproval?.toString() ==
												radioListYesNo[0].value && (
												<>
													{/* Question 4.1: IA Number */}
													<div>
														<strong>{iaNumberInput.label}</strong>
														<br />
														<span className={pageUtils.classes.answerURB}>
															{answers?.iaNumber}
														</span>
													</div>

													{/* Question 4.2: EC Resolution Number */}
													{answers?.ecResolutionNumber && (
														<div>
															<strong>{ecResolutionNumberInput.label}</strong>
															<br />
															<span className={pageUtils.classes.answerURB}>
																{answers?.ecResolutionNumber}
															</span>
														</div>
													)}
												</>
											)}
										</>
									)}
								</>
							)}
						</Collapse>
					</>
				)}

				{subSteps?.details_2 && (
					<>
						<Collapse
							id={2}
							buttonText={pageAssets?.request_form_request_location}
							isOpen={toPrint}
							key={`request_form_request_location`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
												2
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_request_location}
									</h4>
								)}
							</div>

							{/* Question 7 */}

							<div>
								<strong>{isSameLocationInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(
										radioListYesNo,
										answers?.buildingAddressSameHasApplicant
									)}
								</span>
							</div>

							{/* Question 8 */}
							{answers?.buildingAddressSameHasApplicant?.toString() ==
								radioListYesNo[1].value && (
								<>
									<div>
										<strong>{pageAssets?.request_form_location}</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{answers?.addressField ? (
												<>
													{answers?.addressField}
													<br />
												</>
											) : (
												''
											)}

											{answers?.addressLotNumber
												? pageAssets?.label_cadastral_number +
												  ': ' +
												  answers?.addressLotNumber
												: ''}
										</span>
									</div>
									{answers?.addressVoiePublic && (
										<div>
											<strong>
												{
													pageAssets?.request_form_addressVoiePublicSection_public_road
												}
											</strong>
											<br />
											<span>
												{answers?.addressCivicNumber
													? answers?.addressCivicNumber + ', '
													: ''}
												{answers?.addressVoiePublic}
											</span>
										</div>
									)}
									{answers?.addressVoiePublic2 && (
										<div>
											<span>
												{answers?.addressCivicNumber2
													? answers?.addressCivicNumber2 + ', '
													: ''}
												{answers?.addressVoiePublic2}
											</span>
										</div>
									)}

									{answers?.addressVoiePublic3 && (
										<div>
											<span>
												{answers?.addressCivicNumber3
													? answers?.addressCivicNumber3 + ', '
													: ''}
												{answers?.addressVoiePublic3}
											</span>
										</div>
									)}

									{answers?.addressAdditionalInfos && (
										<div>
											<span>{answers?.addressAdditionalInfos}</span>
										</div>
									)}
									<br />
								</>
							)}

							{/* Question 9 */}
							<div>
								<strong>{isLocationOwnedInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(radioListYesNo, answers?.isLocationOwned)}
								</span>
							</div>

							{/* ****** Owner inputs ****** */}
							{answers?.isLocationOwned?.toString() ==
								radioListYesNo[1].value && (
								<>
									{questionAnswer(
										ownerFirstNameInput.label,
										answers?.ownerFirstName
									)}
									{questionAnswer(
										ownerLastNameInput.label,
										answers?.ownerLastName
									)}
									{questionAnswer(ownerEmailInput.label, answers?.ownerEmail)}
									{questionAnswer(
										ownerPhoneNumberInput.label,
										answers?.ownerPhoneNumber
									)}

									<RequestFiles
										subtitle={firstQuestion.label}
										name={firstQuestion.name}
										required={firstQuestion.required}
										files={firstQuestion.files}
										hasError={errors.includes(firstQuestion.name)}
										removeSectionMarginTop
										removePadding
										equivalentMargin
										onSetHasError={onSetErrorFromFileComponent}
										onSetFiles={onSelectFileFirstQuestion}
										maxFiles={firstQuestion.numberFilesRequired}
										isSummary
									>
										<></>
									</RequestFiles>
									<br />
								</>
							)}
						</Collapse>
					</>
				)}

				{subSteps?.details_3 && (
					<>
						<Collapse
							id={3}
							buttonText={pageAssets?.request_form_type_of_use}
							isOpen={toPrint}
							key={`request_form_type_of_use`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
												3
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_type_of_use}
									</h4>
								)}
							</div>

							<div>
								<>
									{/* Question 10 */}
									{questionAnswer(
										mainUseOfExistingBuildingInput.label,
										getLabelFromList(
											radioListMainUseOfExistingBuilding,
											answers?.mainUseOfExistingBuilding
										)
									)}

									{answers?.mainUseOfExistingBuilding?.toString() ==
										REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding
											.fourthOption &&
										questionAnswer(
											isResidentialOrOtherInput.label,
											getLabelFromList(
												radioListResidentialOrOther,
												answers?.isResidentialOrOther
											)
										)}

									{/* Question 13 */}
									{questionAnswer(
										structureTypeInput.label,
										getLabelFromList(
											radioListStructureType,
											answers?.structureType
										)
									)}

									{/* Question 12 */}
									{questionAnswer(
										isTheNewBuildingPartOfAPropertyInput.label,
										getLabelFromList(
											radioListYesNo,
											answers?.existingMainBuildingIsShared
										)
									)}

									{answers?.existingMainBuildingIsShared?.toString() ==
										radioListYesNo[0].value && (
										<RequestFiles
											subtitle={secondQuestion.label}
											name={secondQuestion.name}
											required={secondQuestion.required}
											files={secondQuestion.files}
											hasError={errors.includes(secondQuestion.name)}
											removeSectionMarginTop
											removePadding
											equivalentMargin
											onSetHasError={onSetErrorFromFileComponent}
											onSetFiles={onSelectFileSecondQuestion}
											maxFiles={secondQuestion.numberFilesRequired}
											isSummary
										>
											<></>
										</RequestFiles>
									)}
								</>
							</div>
						</Collapse>
					</>
				)}
				{subSteps?.details_4 && (
					<>
						<Collapse
							id={4}
							buttonText={pageAssets?.request_form_land_developement}
							isOpen={toPrint}
							key={`request_form_land_developement`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4,
												4
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_land_developement}
									</h4>
								)}
							</div>

							<div>
								{isBalconyOrPorchPermit(whatToDoInput.value) && (
									<>
										<div>
											<strong>{typesOfWorkBalconyInput.label}</strong>
											<br />
											<span className={pageUtils.classes.answerURB}>
												<>
													{getLabelsFromString(
														radioListTypesOfWorkBalcony,
														answers?.typesOfWorkBalcony
													).map((answerLabel, index) => {
														return (
															<p
																className={pageUtils.classes.answerURB}
																key={`${index}-typesOfWorkBalcony`}
															>
																{parse(formatHtmlForStrapiText(answerLabel))}
															</p>
														)
													})}
												</>
											</span>
										</div>
										{/* Section A */}
										{isBalconySectionA(answers?.typesOfWorkBalcony) && (
											<>
												<h5 className={pageUtils.classes.h5}>
													{
														pageAssets?.request_form_landDevWorkPermit_typesOfWorkBalcony_firstOption
													}
												</h5>
												{[
													REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding
														.firstOption,
													REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding
														.thirdOption
												].includes(
													answers?.mainUseOfExistingBuilding?.toString() || ''
												) && (
													<>
														{questionAnswer(
															installationTypeInput.label,

															getLabelFromList(
																installationTypeBalconyList,
																answers?.installationType
															)
														)}
														{questionAnswer(
															foundationTypeBalconyInput.label,
															getLabelFromList(
																radioListFoundationTypeBalcony,
																answers?.foundationTypeBalcony
															)
														)}
													</>
												)}

												{questionAnswer(
													locationBalconyInput.label,
													getLabelFromList(
														radioListLocationBalcony,
														answers?.locationBalcony
													)
												)}

												{[
													REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding
														.firstOption,
													REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding
														.thirdOption
												].includes(
													answers?.mainUseOfExistingBuilding?.toString() || ''
												) && (
													<>
														{(answers?.widthBalcony ||
															answers?.depthBalcony ||
															answers?.heightBalcony ||
															answers?.areaBalcony) && (
															<h6 className={pageUtils.classes.h6}>
																{
																	pageAssets?.request_form_buildingConstructionOrAdditionPermit_dimensions
																}
															</h6>
														)}

														{questionAnswer(
															widthBalconyInput.label,
															`${answers?.widthBalcony} ${getLabelFromList(
																radioListMeasurementUnit,
																answers?.widthUnitBalcony
															)}`
														)}

														{questionAnswer(
															depthBalconyInput.label,
															`${answers?.depthBalcony} ${getLabelFromList(
																radioListMeasurementUnit,
																answers?.depthUnitBalcony
															)}`
														)}

														{questionAnswer(
															heightBalconyInput.label,
															`${answers?.heightBalcony} ${getLabelFromList(
																radioListMeasurementUnit,
																answers?.heightUnitBalcony
															)}`
														)}

														{questionAnswer(
															areaBalconyInput.label,
															`${answers?.areaBalcony} ${getLabelFromList(
																radioListMeasurement2Unit,
																answers?.areaUnitBalcony
															)}`
														)}

														{(answers?.structureBalcony ||
															answers?.roofBalcony) && (
															<>
																<h6 className={pageUtils.classes.h6}>
																	{
																		pageAssets?.request_form_buildingConstructionOrAdditionPermit_materials
																	}
																</h6>

																{questionAnswer(
																	structureBalconyInput.label,
																	answers?.structureBalcony
																)}

																{questionAnswer(
																	roofBalconyInput.label,
																	answers?.roofBalcony
																)}
															</>
														)}
													</>
												)}
											</>
										)}
										{/* Section B */}
										{isBalconySectionB(answers?.typesOfWorkBalcony) && (
											<>
												{(answers?.foundationTypeTerrace ||
													answers?.locationTerrace) && (
													<h5 className={pageUtils.classes.h5}>
														{
															pageAssets?.request_form_landDevWorkPermit_typesOfWorkBalcony_secondOption
														}
													</h5>
												)}

												{questionAnswer(
													foundationTypeTerraceInput.label,
													getLabelFromList(
														radioListFoundationTypeTerrace,
														answers?.foundationTypeTerrace
													)
												)}

												{questionAnswer(
													locationTerraceInput.label,
													getLabelFromList(
														radioListLocationTerrace,
														answers?.locationTerrace
													)
												)}

												{answers?.isResidentialOrOther?.toString() ==
													REQUEST_CONFIGS_KEYS.isResidentialOrOther
														.firstOption && (
													<div>
														<strong>{isTerraceIncludeInput.label}</strong>
														<br />
														<span className={pageUtils.classes.answerURB}>
															<>
																{getLabelsFromString(
																	radioListIsTerraceInclude,
																	answers?.isTerraceInclude
																).map((answerLabel, index) => {
																	return (
																		<p
																			className={pageUtils.classes.answerURB}
																			key={`${index}-isTerraceInclude`}
																		>
																			{parse(
																				formatHtmlForStrapiText(answerLabel)
																			)}
																		</p>
																	)
																})}
															</>
														</span>
													</div>
												)}

												{(answers?.heightTerrace || answers?.areaTerrace) && (
													<h6 className={pageUtils.classes.h6}>
														{
															pageAssets?.request_form_buildingConstructionOrAdditionPermit_dimensions
														}
													</h6>
												)}

												{questionAnswer(
													heightTerraceInput.label,
													`${answers?.heightTerrace} ${getLabelFromList(
														radioListMeasurementUnit,
														answers?.heightUnitTerrace
													)}`
												)}

												{questionAnswer(
													areaTerraceInput.label,
													`${answers?.areaTerrace} ${getLabelFromList(
														radioListMeasurement2Unit,
														answers?.areaUnitTerrace
													)}`
												)}
												{(answers?.wallsTerrace ||
													answers?.structureTerrace ||
													answers?.roofTerrace) && (
													<h6 className={pageUtils.classes.h6}>
														{
															pageAssets?.request_form_buildingConstructionOrAdditionPermit_materials
														}
													</h6>
												)}

												{/* wallsTerraceInput */}
												{questionAnswer(
													wallsTerraceInput.label,
													answers?.wallsTerrace
												)}

												{questionAnswer(
													structureTerraceInput.label,
													answers?.structureTerrace
												)}

												{questionAnswer(
													roofTerraceInput.label,
													answers?.roofTerrace
												)}
											</>
										)}
									</>
								)}
							</div>
						</Collapse>
					</>
				)}

				{subSteps?.details_5 && (
					<>
						<Collapse
							id={5}
							buttonText={pageAssets?.request_form_information_on_the_work}
							isOpen={toPrint}
							key={`request_form_information_on_the_work`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5,
												5
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}

								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_information_on_the_work}
									</h4>
								)}
							</div>

							{/* Question 24 */}
							<div>
								<strong>{Q24IsWorkDueToDisasterInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(
										radioListYesNo,
										answers?.isWorkDueToDisaster
									)}
								</span>
							</div>

							{answers?.isWorkDueToDisaster?.toString() ==
								radioListYesNo[0].value && (
								<>
									<div>
										<strong>
											{Q24_1WorkDueToDisasterPrecisionInput.label}
										</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{answers?.workDueToDisasterPrecision}
										</span>
									</div>
								</>
							)}

							{/* Question 25 */}
							<div>
								<strong>{Q25ApproximateCostOfWorkInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{formatNumberWithSpaces(
										answers?.approximateCostOfWork?.toString()
									)}{' '}
									$
								</span>
							</div>

							{/* Question 27 */}
							{questionAnswer(
								Q27IsTreeCuttingRequiredInput.label,
								getLabelFromList(radioListYesNo, answers?.isTreeCuttingRequired)
							)}

							{/* Question 28 */}
							{questionAnswer(
								Q28AdditionalInfoInput.label,
								answers?.additionalInfoQ28
							)}

							{/* Dates des travaux */}
							{(answers?.workStartDate || answers?.workEndDate) && (
								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_work_dates
									}
								</h5>
							)}

							{answers?.workStartDate && (
								<div>
									<strong>{Q29StartDateInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answerURB}>
										{pageUtils.getLocaleDate(answers?.workStartDate!)}
									</span>
								</div>
							)}

							{answers?.workEndDate && (
								<div>
									<strong>{Q30EndDateInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answerURB}>
										{pageUtils.getLocaleDate(answers?.workEndDate!)}
									</span>
								</div>
							)}

							<h5 className={pageUtils.classes.h5}>
								{
									pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneur_executing_work
								}
							</h5>

							{/* Question 31 */}
							{questionAnswer(
								Q31WhoWillBeInChargeOfWorkInput.label,
								getLabelFromList(
									radioListWhoWillBeInChargeOfWork,
									answers?.whoWillBeInChargeOfWork
								)
							)}

							{[
								radioListWhoWillBeInChargeOfWork[1].value,
								radioListWhoWillBeInChargeOfWork[2].value
							].includes(answers?.whoWillBeInChargeOfWork?.toString()!) &&
								questionAnswer(
									Q31_1EntrepreneurUnknownInput.label,
									getLabelFromList(
										radioListYesNo,
										answers?.entrepreneurUnknown1?.toString() || String(false)
									)
								)}

							{questionAnswer(
								Q32CompanyNameExecutingWorkInput.label,
								answers?.companyNameExecutingWork
							)}

							{questionAnswer(
								Q33CompanyNEQNumberInput.label,
								answers?.companyNEQNumber
							)}

							{questionAnswer(
								Q34CompanyRBQNumberInput.label,
								answers?.companyRBQNumber
							)}

							{(answers?.companyRepresentativeName ||
								answers?.companyRepresentativePhoneNumber ||
								answers?.companyRepresentativeEmail) && (
								<h6 className={pageUtils.classes.h6}>
									{
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_representative_or_contact_person
									}
								</h6>
							)}

							{questionAnswer(
								Q35CompanyRepresentativeNameInput.label,
								answers?.companyRepresentativeName
							)}

							{questionAnswer(
								Q36CompanyRepresentativePhoneNumberInput.label,
								answers?.companyRepresentativePhoneNumber
							)}

							{questionAnswer(
								Q37CompanyRepresentativeEmailInput.label,
								answers?.companyRepresentativeEmail
							)}

							{(answers?.companyAddress ||
								answers?.companyCity ||
								answers?.companyPostalCode) && (
								<h6 className={pageUtils.classes.h6}>
									{
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_company_address
									}
								</h6>
							)}

							{questionAnswer(
								Q38CompanyAddressInput.label,
								answers?.companyAddress
							)}

							{questionAnswer(Q39CompanyCityInput.label, answers?.companyCity)}

							{questionAnswer(
								Q40CompanyPostalCodeInput.label,
								answers?.companyPostalCode
							)}
						</Collapse>
					</>
				)}

				{subSteps?.requiredDocument && (
					<>
						<Collapse
							id={6}
							buttonText={pageAssets?.request_form_required_documents}
							isOpen={toPrint}
							key={`request_form_required_documents`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
												6
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_required_documents}
									</h4>
								)}
							</div>

							{isBalconyOrPorchPermit(answers?.whatToDo) && (
								<>
									{renderBalconyRequestFiles('required', true)}
									{renderBalconyRequestFiles('optional', true)}
								</>
							)}
						</Collapse>
					</>
				)}
			</section>
		)
	}

	return (
		<>
			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_type_de_demandes}
						</h4>

						{/* Question 1 */}
						<RadioInput
							name={whatToDoInput.name}
							label={whatToDoInput.label}
							value={whatToDoInput.value}
							list={radioListWhatToDo}
							direction={Direction.vertical}
							hasError={errors!.includes(whatToDoInput.name)}
							onChange={onSetWhatToDoInput}
							ref={whatToDoInput.ref as RefObject<HTMLInputElement>}
							required={whatToDoInput.required}
							hasBackgroundWrapper={true}
						/>

						{/* Question 2 */}
						<RadioInput
							name={requestAsInput.name}
							label={requestAsInput.label}
							value={requestAsInput.value}
							list={radioListRequestAs}
							direction={Direction.vertical}
							hasError={errors!.includes(requestAsInput.name)}
							onChange={onSetRequestAsInput}
							ref={requestAsInput.ref as RefObject<HTMLInputElement>}
							required={requestAsInput.required}
							hasBackgroundWrapper={true}
						/>

						{[
							REQUEST_CONFIGS_KEYS.requestAs.entreprise,
							REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager
						].includes(requestAsInput.value) && (
							<>
								{/* Question 2.1 */}
								<TextInput
									id={companyNameInput.name}
									label={companyNameInput.label}
									value={companyNameInput.value}
									onChange={onSetCompanyNameInput}
									hasError={errors?.includes(companyNameInput.name)}
									required={companyNameInput.required}
									ref={companyNameInput.ref as RefObject<HTMLInputElement>}
									maxCharacters={100}
								/>

								{/* Question 2.2 */}
								<TextInput
									id={representativeNameInput.name}
									label={representativeNameInput.label}
									value={representativeNameInput.value}
									onChange={onSetRepresentativeNameInput}
									hasError={errors?.includes(representativeNameInput.name)}
									required={representativeNameInput.required}
									ref={
										representativeNameInput.ref as RefObject<HTMLInputElement>
									}
									maxCharacters={100}
								/>
							</>
						)}

						<>
							{/* Architectural implementation and integration plan (PIIA) */}
							<h5 className={pageUtils.classes.h5}>
								{
									pageAssets?.request_form_buildingConstructionOrAdditionPermit_piia_title
								}
							</h5>

							{/* Question 3 */}
							<RadioInput
								name={isSubjectToPiiaApprovalInput.name}
								label={isSubjectToPiiaApprovalInput.label}
								value={isSubjectToPiiaApprovalInput.value}
								list={radioListYesNo}
								direction={Direction.vertical}
								hasError={errors!.includes(isSubjectToPiiaApprovalInput.name)}
								onChange={onSetIsSubjectToPiiaApprovalInput}
								ref={
									isSubjectToPiiaApprovalInput.ref as RefObject<HTMLInputElement>
								}
								required={isSubjectToPiiaApprovalInput.required}
								hasBackgroundWrapper={true}
								classWrapper={classes.reducedMarginBottom}
								description={
									pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSubjectToPiiaApproval_description
								}
							/>

							{isSubjectToPiiaApprovalInput.value ==
								radioListYesNo[0].value && (
								<>
									{/* Question 4 */}
									<RadioInput
										name={hasBeenSubjectToApprovalInput.name}
										label={hasBeenSubjectToApprovalInput.label}
										value={hasBeenSubjectToApprovalInput.value}
										list={radioListYesNo}
										direction={Direction.vertical}
										hasError={errors!.includes(
											hasBeenSubjectToApprovalInput.name
										)}
										onChange={onSetHasBeenSubjectToApprovalInput}
										ref={
											hasBeenSubjectToApprovalInput.ref as RefObject<HTMLInputElement>
										}
										required={hasBeenSubjectToApprovalInput.required}
										hasBackgroundWrapper={true}
										classWrapper={classes.reducedMarginBottom}
									/>
									{hasBeenSubjectToApprovalInput.value ==
										radioListYesNo[0].value && (
										<>
											<MaskedTextInput
												id={iaNumberInput.name}
												label={iaNumberInput.label}
												value={iaNumberInput.value}
												onChange={onSetIaNumberInput}
												hasError={errors.includes(iaNumberInput.name)}
												required={iaNumberInput.required}
												ref={iaNumberInput.ref as RefObject<HTMLInputElement>}
												max={12}
												min={12}
												mask="IA-0000-0000"
												placeholder="IA-AAAA-XXXX"
												errors={errors}
												setError={onFixError}
												schema={/^IA-\d{4}-\d{4}$/}
											/>

											<MaskedTextInput
												id={ecResolutionNumberInput.name}
												label={ecResolutionNumberInput.label}
												value={ecResolutionNumberInput.value}
												onChange={onSetEcResolutionNumberInput}
												hasError={errors.includes(ecResolutionNumberInput.name)}
												required={ecResolutionNumberInput.required}
												ref={
													ecResolutionNumberInput.ref as RefObject<HTMLInputElement>
												}
												max={16}
												min={16}
												mask="CE-00000000-0000"
												placeholder="CE-AAAAMMJJ-XXXX"
												errors={errors}
												setError={onFixError}
												schema={/^CE-\d{8}-\d{4}$/}
											/>
										</>
									)}
								</>
							)}
						</>
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_request_location}
						</h4>

						<div className={classes.adresseBlock}>
							<div className={pageUtils.classes.fieldsContainer}>
								<div>
									<strong>{pageAssets?.label_address}</strong>
									<br />
									{authUser?.profile?.address.address}
									<br />
									{authUser?.profile?.address.city}
									{authUser?.profile?.address.state
										? ` (${authUser?.profile?.address.state}) `
										: ' '}
									{authUser?.profile?.address.postalCode}
									<br />
									{authUser?.profile?.address.apartment
										? `${formatStrapiText(
												pageAssets?.label_apartment_office
										  )} ${authUser?.profile?.address.apartment}`
										: ''}
								</div>

								{buildingBatchNumberInput.value && (
									<div>
										<strong>{pageAssets?.lot_number_label}</strong>
										<br />
										{buildingBatchNumberInput.value}
									</div>
								)}
							</div>
						</div>

						{/* Question 7 */}

						<RadioInput
							name={isSameLocationInput.name}
							label={isSameLocationInput.label}
							value={isSameLocationInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isSameLocationInput.name)}
							onChange={onSetIsSameLocationInput}
							ref={isSameLocationInput.ref as RefObject<HTMLInputElement>}
							required={isSameLocationInput.required}
							hasBackgroundWrapper={true}
							classWrapper={classes.reducedMarginBottom}
						/>

						{/* Question 8 */}
						{showAdresseBlock == true && (
							<>
								<AddressVoiePublicSection
									label={pageAssets?.request_form_location}
									errors={errors}
									inputs={inputs}
									onFixError={onFixError}
									onSetInputs={onSetInputs}
									isConcernedInfosRequired={
										isSameLocationInput.value == radioListYesNo[1].value
									}
									showButtonUserLocation={false}
									displayAddressDetails={true}
									requiredFields={[
										'addressOption',
										'addressField',
										'addressLotNumber'
									]}
									subStep={REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2}
									addressOptions={radioListAddressOption}
								/>

								<br />
							</>
						)}

						{isSameLocationInput.value == radioListYesNo[0].value &&
							isAddressOutsideLaval && (
								<Notification
									text={
										pageAssets?.request_form_isAddressOutsideLavalMessageShow_notification_label
									}
									type="warning"
									hasHtml
								/>
							)}

						{/* Question 9 */}
						<RadioInput
							name={isLocationOwnedInput.name}
							label={isLocationOwnedInput.label}
							value={isLocationOwnedInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isLocationOwnedInput.name)}
							onChange={onSetIsLocationOwnedInput}
							ref={isLocationOwnedInput.ref as RefObject<HTMLInputElement>}
							required={isLocationOwnedInput.required}
							hasBackgroundWrapper={true}
							classWrapper={classes.reducedMarginBottom}
						/>

						{/* ****** Owner inputs ****** */}
						{isLocationOwnedInput.value == radioListYesNo[1].value && (
							<>
								<Notification
									text={
										pageAssets?.request_form_ownerPowerOfAttorney_notification
									}
									type="info"
									hasHtml
								/>
								<div
									className={joinClasses([
										pageUtils.classes.fieldsContainer,
										classes.greyBackgroundBlock
									])}
								>
									<TextInput
										id={ownerFirstNameInput.name}
										label={ownerFirstNameInput.label}
										value={ownerFirstNameInput.value}
										onChange={onSetOwnerFirstNameInput}
										hasError={errors.includes(ownerFirstNameInput.name)}
										required={ownerFirstNameInput.required}
										ref={ownerFirstNameInput.ref as RefObject<HTMLInputElement>}
										classWrapper={pageUtils?.classes.textFieldContainer}
										maxCharacters={50}
									/>
									<TextInput
										id={ownerLastNameInput.name}
										label={ownerLastNameInput.label}
										value={ownerLastNameInput.value}
										onChange={onSetOwnerLastNameInput}
										hasError={errors.includes(ownerLastNameInput.name)}
										required={ownerLastNameInput.required}
										ref={ownerLastNameInput.ref as RefObject<HTMLInputElement>}
										classWrapper={pageUtils?.classes.textFieldContainer}
										maxCharacters={50}
									/>

									<TextInput
										id={ownerEmailInput.name}
										label={ownerEmailInput.label}
										value={ownerEmailInput.value}
										onChange={onSetOwnerEmailInput}
										hasError={errors?.includes(ownerEmailInput.name)}
										type="email"
										onSetError={onFixError}
										schema={formUtils.MASKS.email}
										schemaErrorText={pageAssets?.label_emailErrorText}
										required={ownerEmailInput.required}
										ref={ownerEmailInput.ref as RefObject<HTMLInputElement>}
										maxCharacters={100}
									/>
									<MaskedTextInput
										id={ownerPhoneNumberInput.name}
										label={ownerPhoneNumberInput.label}
										value={ownerPhoneNumberInput.value}
										onChange={onSetOwnerPhoneNumberInput}
										hasError={errors?.includes(ownerPhoneNumberInput.name)}
										type="tel"
										min={10}
										max={14}
										mask={formUtils.MASKS.phoneNumber}
										placeholder={formUtils.MASKS.phoneNumber}
										errors={errors ? errors : []}
										setError={onFixError}
										schema={PHONE_NUMBER_REGEX}
										required={ownerPhoneNumberInput.required}
										ref={
											ownerPhoneNumberInput.ref as RefObject<HTMLInputElement>
										}
									/>
								</div>

								<RequestFiles
									subtitle={firstQuestion.label}
									name={firstQuestion.name}
									required
									files={firstQuestion.files}
									hasError={errors.includes(firstQuestion.name)}
									removeSectionMarginTop
									removePadding
									equivalentMargin
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectFileFirstQuestion}
									subSteps={subSteps}
									maxFiles={firstQuestion.numberMaxFiles}
									isNewDesign
								></RequestFiles>
								<br />
							</>
						)}
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_type_of_use}
						</h4>

						<RadioInput
							name={mainUseOfExistingBuildingInput.name}
							label={mainUseOfExistingBuildingInput.label}
							value={mainUseOfExistingBuildingInput.value}
							list={radioListMainUseOfExistingBuilding}
							direction={Direction.vertical}
							hasError={errors!.includes(mainUseOfExistingBuildingInput.name)}
							onChange={onSetMainUseOfExistingBuildingInput}
							ref={
								mainUseOfExistingBuildingInput.ref as RefObject<HTMLInputElement>
							}
							required={mainUseOfExistingBuildingInput.required}
							hasBackgroundWrapper={true}
						/>

						{mainUseOfExistingBuildingInput.value ==
							REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.fourthOption && (
							<RadioInput
								name={isResidentialOrOtherInput.name}
								label={isResidentialOrOtherInput.label}
								value={isResidentialOrOtherInput.value}
								list={radioListResidentialOrOther}
								direction={Direction.vertical}
								hasError={errors!.includes(isResidentialOrOtherInput.name)}
								onChange={onSetIsResidentialOrOtherInput}
								ref={
									isResidentialOrOtherInput.ref as RefObject<HTMLInputElement>
								}
								required={isResidentialOrOtherInput.required}
								hasBackgroundWrapper={true}
							/>
						)}

						<RadioInput
							name={structureTypeInput.name}
							label={structureTypeInput.label}
							value={structureTypeInput.value}
							list={radioListStructureType}
							direction={Direction.vertical}
							hasError={errors!.includes(structureTypeInput.name)}
							onChange={onSetStructureTypeInput}
							ref={structureTypeInput.ref as RefObject<HTMLInputElement>}
							required={structureTypeInput.required}
							hasBackgroundWrapper={true}
						/>

						{/* Question 12 */}
						<RadioInput
							name={isTheNewBuildingPartOfAPropertyInput.name}
							label={isTheNewBuildingPartOfAPropertyInput.label}
							value={isTheNewBuildingPartOfAPropertyInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(
								isTheNewBuildingPartOfAPropertyInput.name
							)}
							onChange={onSetIsTheNewBuildingPartOfAPropertyInput}
							ref={
								isTheNewBuildingPartOfAPropertyInput.ref as RefObject<HTMLInputElement>
							}
							required={isTheNewBuildingPartOfAPropertyInput.required}
							hasBackgroundWrapper={true}
						/>

						{isTheNewBuildingPartOfAPropertyInput.value ==
							radioListYesNo[0].value && (
							<>
								<RequestFiles
									subtitle={secondQuestion.label}
									name={secondQuestion.name}
									required
									files={secondQuestion.files}
									hasError={errors.includes(secondQuestion.name)}
									removeSectionMarginTop
									removePadding
									equivalentMargin
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectFileSecondQuestion}
									subSteps={subSteps}
									maxFiles={secondQuestion.numberMaxFiles}
									isNewDesign
								></RequestFiles>
								<br />
							</>
						)}
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_land_developement}
						</h4>

						{isBalconyOrPorchPermit(whatToDoInput.value) && (
							<>
								{/* typesOfWorkBalconyInput */}

								<CheckboxInput
									name={typesOfWorkBalconyInput.name}
									label={typesOfWorkBalconyInput.label}
									description={typesOfWorkBalconyInput.description}
									value={`${typesOfWorkBalconyInput.value}`}
									list={radioListTypesOfWorkBalcony}
									onChange={onSetTypesOfWorkBalconyInput}
									required={typesOfWorkBalconyInput.required}
									hasError={errors.includes(typesOfWorkBalconyInput.name)}
									ref={
										typesOfWorkBalconyInput.ref as RefObject<HTMLInputElement>
									}
									errors={errors}
									shouldNotDivide
									hasBackgroundWrapper
									onFixError={onFixError}
									newCheckValue
								/>

								{/* Section A */}
								{isBalconySectionA(typesOfWorkBalconyInput.value) && (
									<>
										<h5 className={pageUtils.classes.h5}>
											{
												pageAssets?.request_form_landDevWorkPermit_typesOfWorkBalcony_firstOption
											}
										</h5>

										{[
											REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding
												.firstOption,
											REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.thirdOption
										].includes(mainUseOfExistingBuildingInput.value) && (
											<>
												<RadioInput
													name={installationTypeInput.name}
													label={installationTypeInput.label}
													value={installationTypeInput.value}
													list={installationTypeBalconyList}
													direction={Direction.vertical}
													hasError={errors!.includes(
														installationTypeInput.name
													)}
													onChange={onSetInstallationTypeInput}
													ref={
														installationTypeInput.ref as RefObject<HTMLInputElement>
													}
													required={installationTypeInput.required}
													hasBackgroundWrapper={true}
													alternateDisplay={true}
												/>

												<SelectInput
													id={foundationTypeBalconyInput.name}
													label={foundationTypeBalconyInput.label}
													value={foundationTypeBalconyInput.value}
													list={radioListFoundationTypeBalcony}
													hasError={errors!.includes(
														foundationTypeBalconyInput.name
													)}
													onChange={onSetFoundationTypeBalconyInput}
													ref={
														foundationTypeBalconyInput.ref as RefObject<HTMLSelectElement>
													}
													required={foundationTypeBalconyInput.required}
												/>
												{foundationTypeBalconyInput.value ==
													radioListFoundationTypeBalcony[2].value && (
													<Notification
														text={
															pageAssets?.request_form_buildingConstructionOrAdditionPermit_foundationTypeBalcony_notification
														}
														type="info"
														hasHtml
													/>
												)}
											</>
										)}

										<SelectInput
											id={locationBalconyInput.name}
											label={locationBalconyInput.label}
											value={locationBalconyInput.value}
											list={radioListLocationBalcony}
											hasError={errors!.includes(locationBalconyInput.name)}
											onChange={onSetLocationBalconyInput}
											ref={
												locationBalconyInput.ref as RefObject<HTMLSelectElement>
											}
											required={locationBalconyInput.required}
										/>
										{[
											REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding
												.firstOption,
											REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.thirdOption
										].includes(mainUseOfExistingBuildingInput.value) && (
											<>
												<h6 className={pageUtils.classes.h6}>
													{
														pageAssets?.request_form_buildingConstructionOrAdditionPermit_dimensions
													}
												</h6>

												<div className={pageUtils.classes.fieldsContainer}>
													{renderMeasurementInput(
														widthBalconyInput,
														onSetWidthBalconyInput,
														widthUnitBalconyInput,
														onSetWidthUnitBalconyInput,
														radioListMeasurementUnit,
														6
													)}
												</div>

												<div className={pageUtils.classes.fieldsContainer}>
													{/* depthBalconyInput */}
													{renderMeasurementInput(
														depthBalconyInput,
														onSetDepthBalconyInput,
														depthUnitBalconyInput,
														onSetDepthUnitBalconyInput,
														radioListMeasurementUnit,
														6
													)}
												</div>

												<div className={pageUtils.classes.fieldsContainer}>
													{/* heightBalconyInput*/}
													{renderMeasurementInput(
														heightBalconyInput,
														onSetHeightBalconyInput,
														heightUnitBalconyInput,
														onSetHeightUnitBalconyInput,
														radioListMeasurementUnit,
														6
													)}
												</div>
												<div className={pageUtils.classes.fieldsContainer}>
													{/* areaBalconyInput */}
													{renderMeasurementInput(
														areaBalconyInput,
														onSetAreaBalconyInput,
														areaUnitBalconyInput,
														onSetAreaUnitBalconyInput,
														radioListMeasurement2Unit,
														6
													)}
												</div>

												<h6 className={pageUtils.classes.h6}>
													{
														pageAssets?.request_form_buildingConstructionOrAdditionPermit_materials
													}
												</h6>
												{/* structureBalconyInput */}
												<TextInput
													id={structureBalconyInput.name}
													label={structureBalconyInput.label}
													value={structureBalconyInput.value}
													onChange={onSetStructureBalconyInput}
													hasError={errors.includes(structureBalconyInput.name)}
													required={structureBalconyInput.required}
													ref={
														structureBalconyInput.ref as RefObject<HTMLInputElement>
													}
													maxCharacters={50}
												/>
												{/* roofBalconyInput
												 */}
												<TextInput
													id={roofBalconyInput.name}
													label={roofBalconyInput.label}
													value={roofBalconyInput.value}
													onChange={onSetRoofBalconyInput}
													hasError={errors.includes(roofBalconyInput.name)}
													required={roofBalconyInput.required}
													ref={
														roofBalconyInput.ref as RefObject<HTMLInputElement>
													}
													maxCharacters={50}
												/>
											</>
										)}
									</>
								)}

								{/* Section B  */}
								{isBalconySectionB(typesOfWorkBalconyInput.value) && (
									<>
										<h5 className={pageUtils.classes.h5}>
											{
												pageAssets?.request_form_landDevWorkPermit_typesOfWorkBalcony_secondOption
											}
										</h5>

										{isResidentialOrOtherInput.value ==
											REQUEST_CONFIGS_KEYS.isResidentialOrOther.firstOption && (
											<Notification
												text={
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_typesOfWorkBalcony_commercialUse_notification
												}
												type="info"
												hasHtml
											/>
										)}

										<SelectInput
											id={foundationTypeTerraceInput.name}
											label={foundationTypeTerraceInput.label}
											value={foundationTypeTerraceInput.value}
											list={radioListFoundationTypeTerrace}
											hasError={errors!.includes(
												foundationTypeTerraceInput.name
											)}
											onChange={onSetFoundationTypeTerraceInput}
											ref={
												foundationTypeTerraceInput.ref as RefObject<HTMLSelectElement>
											}
											required={foundationTypeTerraceInput.required}
										/>

										<SelectInput
											id={locationTerraceInput.name}
											label={locationTerraceInput.label}
											value={locationTerraceInput.value}
											list={radioListLocationTerrace}
											hasError={errors!.includes(locationTerraceInput.name)}
											onChange={onSetLocationTerraceInput}
											ref={
												locationTerraceInput.ref as RefObject<HTMLSelectElement>
											}
											required={locationTerraceInput.required}
										/>

										{isResidentialOrOtherInput.value ==
											REQUEST_CONFIGS_KEYS.isResidentialOrOther.firstOption && (
											<CheckboxInput
												name={isTerraceIncludeInput.name}
												label={isTerraceIncludeInput.label}
												description={isTerraceIncludeInput.description}
												value={`${isTerraceIncludeInput.value}`}
												list={radioListIsTerraceInclude}
												onChange={onSetIsTerraceIncludeInput}
												required={isTerraceIncludeInput.required}
												hasError={errors.includes(isTerraceIncludeInput.name)}
												ref={
													isTerraceIncludeInput.ref as RefObject<HTMLInputElement>
												}
												errors={errors}
												shouldNotDivide
												hasBackgroundWrapper
												onFixError={onFixError}
												newCheckValue
											/>
										)}

										<h6 className={pageUtils.classes.h6}>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_dimensions
											}
										</h6>

										<div className={pageUtils.classes.fieldsContainer}>
											{renderMeasurementInput(
												heightTerraceInput,
												onSetHeightTerraceInput,
												heightUnitTerraceInput,
												onSetHeightUnitTerraceInput,
												radioListMeasurementUnit,
												6
											)}

											{renderMeasurementInput(
												areaTerraceInput,
												onSetAreaTerraceInput,
												areaUnitTerraceInput,
												onSetAreaUnitTerraceInput,
												radioListMeasurement2Unit,
												6
											)}
										</div>

										<h6 className={pageUtils.classes.h6}>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_materials
											}
										</h6>

										{/* structureBalconyInput */}
										<TextInput
											id={wallsTerraceInput.name}
											label={wallsTerraceInput.label}
											value={wallsTerraceInput.value}
											onChange={onSetWallsTerraceInput}
											hasError={errors.includes(wallsTerraceInput.name)}
											required={wallsTerraceInput.required}
											ref={wallsTerraceInput.ref as RefObject<HTMLInputElement>}
											maxCharacters={50}
										/>
										{/* structureTerraceInput */}
										<TextInput
											id={structureTerraceInput.name}
											label={structureTerraceInput.label}
											value={structureTerraceInput.value}
											onChange={onSetStructureTerraceInput}
											hasError={errors.includes(structureTerraceInput.name)}
											required={structureTerraceInput.required}
											ref={
												structureTerraceInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={50}
										/>
										{/* roofTerraceInput
										 */}
										<TextInput
											id={roofTerraceInput.name}
											label={roofTerraceInput.label}
											value={roofTerraceInput.value}
											onChange={onSetRoofTerraceInput}
											hasError={errors.includes(roofTerraceInput.name)}
											required={roofTerraceInput.required}
											ref={roofTerraceInput.ref as RefObject<HTMLInputElement>}
											maxCharacters={50}
										/>
									</>
								)}
							</>
						)}
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_information_on_the_work}
						</h4>

						<RadioInput
							name={Q24IsWorkDueToDisasterInput.name}
							label={Q24IsWorkDueToDisasterInput.label}
							value={Q24IsWorkDueToDisasterInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(Q24IsWorkDueToDisasterInput.name)}
							required={Q24IsWorkDueToDisasterInput.required}
							ref={
								Q24IsWorkDueToDisasterInput.ref as RefObject<HTMLInputElement>
							}
							hasBackgroundWrapper={true}
							onChange={onSetQ24IsWorkDueToDisasterInput}
							classWrapper={classes.reducedMarginBottom}
						/>

						{Q24IsWorkDueToDisasterInput.value == radioListYesNo[0].value && (
							<>
								<TextInput
									id={Q24_1WorkDueToDisasterPrecisionInput.name}
									label={Q24_1WorkDueToDisasterPrecisionInput.label}
									value={Q24_1WorkDueToDisasterPrecisionInput.value}
									onChange={onSetQ24_1WorkDueToDisasterPrecisionInput}
									hasError={errors!.includes(
										Q24_1WorkDueToDisasterPrecisionInput.name
									)}
									required={Q24_1WorkDueToDisasterPrecisionInput.required}
									ref={
										Q24_1WorkDueToDisasterPrecisionInput.ref as RefObject<HTMLInputElement>
									}
									maxCharacters={100}
								/>
							</>
						)}

						<MaskedThousandsSeparatorInput
							id={Q25ApproximateCostOfWorkInput.name}
							label={Q25ApproximateCostOfWorkInput.label}
							value={Q25ApproximateCostOfWorkInput.value}
							onChange={onSetQ25ApproximateCostOfWorkInput}
							hasError={errors.includes(Q25ApproximateCostOfWorkInput.name)}
							required={Q25ApproximateCostOfWorkInput.required}
							ref={
								Q25ApproximateCostOfWorkInput.ref as RefObject<HTMLInputElement>
							}
							errors={errors}
							setError={onFixError}
							displayCurrency
						/>

						<RadioInput
							name={Q27IsTreeCuttingRequiredInput.name}
							label={Q27IsTreeCuttingRequiredInput.label}
							value={Q27IsTreeCuttingRequiredInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(Q27IsTreeCuttingRequiredInput.name)}
							required={Q27IsTreeCuttingRequiredInput.required}
							ref={
								Q27IsTreeCuttingRequiredInput.ref as RefObject<HTMLInputElement>
							}
							hasBackgroundWrapper={true}
							onChange={onSetQ27IsTreeCuttingRequiredInput}
							description={Q27IsTreeCuttingRequiredInput.description}
						/>

						<TextAreaInput
							id={Q28AdditionalInfoInput.name}
							label={Q28AdditionalInfoInput.label}
							value={Q28AdditionalInfoInput.value}
							onChange={onSetQ28AdditionalInfoInput}
							hasError={errors!.includes(Q28AdditionalInfoInput.name)}
							required={Q28AdditionalInfoInput.required}
							ref={Q28AdditionalInfoInput.ref as RefObject<HTMLTextAreaElement>}
							maxCharacters={100}
							hasDescriptionLabel={Q28AdditionalInfoInput.description}
							displayDescriptionTop
							descriptionAlignment="left"
							rows={4}
						/>

						{/* Dates des travaux */}
						<h5 className={pageUtils.classes.h5}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_work_dates
							}
						</h5>

						<div className={pageUtils.classes.fieldsContainer}>
							<DateFilterInput
								id={Q29StartDateInput.name}
								label={Q29StartDateInput.label}
								value={Q29StartDateInput.value}
								onChange={onSetQ29StartDateInput}
								hasError={errors!.includes(Q29StartDateInput.name)}
								required={Q29StartDateInput.required}
								dateFormat="dd/MM/yyyy"
								minDate={new Date()}
							/>

							<DateFilterInput
								id={Q30EndDateInput.name}
								label={Q30EndDateInput.label}
								value={Q30EndDateInput.value}
								onChange={onSetQ30EndDateInput}
								hasError={errors!.includes(Q30EndDateInput.name)}
								required={Q30EndDateInput.required}
								dateFormat="dd/MM/yyyy"
								minDate={new Date(Q29StartDateInput.value + 'T12:00:00')}
								disabled={Q29StartDateInput.value == ''}
							/>
						</div>

						{/* Entrepreneur exécutant les travaux de construction. */}
						<h5 className={pageUtils.classes.h5}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneur_executing_work
							}
						</h5>

						<RadioInput
							name={Q31WhoWillBeInChargeOfWorkInput.name}
							label={Q31WhoWillBeInChargeOfWorkInput.label}
							value={Q31WhoWillBeInChargeOfWorkInput.value}
							list={
								requestAsInput.value ==
								radioListWhoWillBeInChargeOfWork[0].value
									? radioListWhoWillBeInChargeOfWork
									: radioListWhoWillBeInChargeOfWork.slice(1)
							}
							direction={Direction.vertical}
							hasError={errors!.includes(Q31WhoWillBeInChargeOfWorkInput.name)}
							required={Q31WhoWillBeInChargeOfWorkInput.required}
							ref={
								Q31WhoWillBeInChargeOfWorkInput.ref as RefObject<HTMLInputElement>
							}
							hasBackgroundWrapper={true}
							onChange={onSetQ31WhoWillBeInChargeOfWorkInput}
						/>

						{/* <br /> */}
						{[
							radioListWhoWillBeInChargeOfWork[1].value,
							radioListWhoWillBeInChargeOfWork[2].value
						].includes(Q31WhoWillBeInChargeOfWorkInput.value) && (
							<div className={classes.checkbox2Container}>
								<span
									className={classes.checkboxWrapper}
									style={{ marginTop: '10px' }}
								>
									<input
										onClick={(e: any) =>
											onSetQ31_1EntrepreneurUnknownInput(e.target.checked)
										}
										value={Q31_1EntrepreneurUnknownInput.value?.toString()}
										className={classes.checkbox2}
										checked={
											Q31_1EntrepreneurUnknownInput.value.toString() == 'true'
										}
										type="checkbox"
										name={Q31_1EntrepreneurUnknownInput.name}
										id={Q31_1EntrepreneurUnknownInput.labelKey}
									/>
									<label
										className={classes.checkbox2Label}
										htmlFor={Q31_1EntrepreneurUnknownInput.labelKey}
									>
										{Q31_1EntrepreneurUnknownInput.label}
									</label>
								</span>
							</div>
						)}

						<TextInput
							id={Q32CompanyNameExecutingWorkInput.name}
							label={Q32CompanyNameExecutingWorkInput.label}
							value={Q32CompanyNameExecutingWorkInput.value}
							onChange={onSetQ32CompanyNameExecutingWorkInput}
							hasError={errors!.includes(Q32CompanyNameExecutingWorkInput.name)}
							required={Q32CompanyNameExecutingWorkInput.required}
							ref={
								Q32CompanyNameExecutingWorkInput.ref as RefObject<HTMLInputElement>
							}
							maxCharacters={50}
						/>
						<div className={pageUtils.classes.fieldsContainer}>
							<TextInput
								id={Q33CompanyNEQNumberInput.name}
								label={Q33CompanyNEQNumberInput.label}
								value={Q33CompanyNEQNumberInput.value}
								onChange={onSetQ33CompanyNEQNumberInput}
								hasError={errors!.includes(Q33CompanyNEQNumberInput.name)}
								required={Q33CompanyNEQNumberInput.required}
								ref={
									Q33CompanyNEQNumberInput.ref as RefObject<HTMLInputElement>
								}
								maxCharacters={20}
							/>

							<TextInput
								id={Q34CompanyRBQNumberInput.name}
								label={Q34CompanyRBQNumberInput.label}
								value={Q34CompanyRBQNumberInput.value}
								onChange={onSetQ34CompanyRBQNumberInput}
								hasError={errors!.includes(Q34CompanyRBQNumberInput.name)}
								required={Q34CompanyRBQNumberInput.required}
								ref={
									Q34CompanyRBQNumberInput.ref as RefObject<HTMLInputElement>
								}
								maxCharacters={20}
							/>
						</div>

						{/* Représentant(e) ou personne contact */}
						<h6 className={pageUtils.classes.h6} style={{ marginTop: '20px' }}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_representative_or_contact_person
							}
						</h6>

						<TextInput
							id={Q35CompanyRepresentativeNameInput.name}
							label={Q35CompanyRepresentativeNameInput.label}
							value={Q35CompanyRepresentativeNameInput.value}
							onChange={onSetQ35CompanyRepresentativeNameInput}
							hasError={errors!.includes(
								Q35CompanyRepresentativeNameInput.name
							)}
							required={Q35CompanyRepresentativeNameInput.required}
							ref={
								Q35CompanyRepresentativeNameInput.ref as RefObject<HTMLInputElement>
							}
							maxCharacters={100}
						/>

						<div className={pageUtils.classes.fieldsContainer}>
							<MaskedTextInput
								id={Q36CompanyRepresentativePhoneNumberInput.name}
								label={Q36CompanyRepresentativePhoneNumberInput.label}
								value={Q36CompanyRepresentativePhoneNumberInput.value}
								onChange={onSetQ36CompanyRepresentativePhoneNumberInput}
								hasError={errors?.includes(
									Q36CompanyRepresentativePhoneNumberInput.name
								)}
								type="tel"
								min={10}
								max={14}
								mask={formUtils.MASKS.phoneNumber}
								placeholder={formUtils.MASKS.phoneNumber}
								errors={errors ? errors : []}
								setError={onFixError}
								schema={PHONE_NUMBER_REGEX}
								required={Q36CompanyRepresentativePhoneNumberInput.required}
								ref={
									Q36CompanyRepresentativePhoneNumberInput.ref as RefObject<HTMLInputElement>
								}
							/>

							<TextInput
								id={Q37CompanyRepresentativeEmailInput.name}
								label={Q37CompanyRepresentativeEmailInput.label}
								value={Q37CompanyRepresentativeEmailInput.value}
								onChange={onSetQ37CompanyRepresentativeEmailInput}
								hasError={errors!.includes(
									Q37CompanyRepresentativeEmailInput.name
								)}
								required={Q37CompanyRepresentativeEmailInput.required}
								ref={
									Q37CompanyRepresentativeEmailInput.ref as RefObject<HTMLInputElement>
								}
								maxCharacters={100}
								type="email"
								schema={formUtils.MASKS.email}
								schemaErrorText={pageAssets.label_emailErrorText}
								onSetError={onFixError}
							/>
						</div>

						{/* Adresse de l'entreprise */}
						<h6 className={pageUtils.classes.h6} style={{ marginTop: '20px' }}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_company_address
							}
						</h6>

						<TextInput
							id={Q38CompanyAddressInput.name}
							label={Q38CompanyAddressInput.label}
							value={Q38CompanyAddressInput.value}
							onChange={onSetQ38CompanyAddressInput}
							hasError={errors!.includes(Q38CompanyAddressInput.name)}
							required={Q38CompanyAddressInput.required}
							ref={Q38CompanyAddressInput.ref as RefObject<HTMLInputElement>}
							maxCharacters={50}
						/>

						<div className={pageUtils.classes.fieldsContainer}>
							<TextInput
								id={Q39CompanyCityInput.name}
								label={Q39CompanyCityInput.label}
								value={Q39CompanyCityInput.value}
								onChange={onSetQ39CompanyCityInput}
								hasError={errors!.includes(Q39CompanyCityInput.name)}
								required={Q39CompanyCityInput.required}
								ref={Q39CompanyCityInput.ref as RefObject<HTMLInputElement>}
								maxCharacters={50}
							/>

							<MaskedTextInput
								id={Q40CompanyPostalCodeInput.name}
								label={Q40CompanyPostalCodeInput.label}
								value={Q40CompanyPostalCodeInput.value}
								onChange={onSetQ40CompanyPostalCodeInput}
								hasError={errors!.includes(Q40CompanyPostalCodeInput.name)}
								required={Q40CompanyPostalCodeInput.required}
								ref={
									Q40CompanyPostalCodeInput.ref as RefObject<HTMLInputElement>
								}
								max={7}
								min={7}
								mask={formUtils.MASKS.postalCode}
								placeholder="A1A 1A1"
								errors={errors}
								setError={onFixError}
								schema={POSTAL_CODE_REGEX}
							/>
						</div>
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
					}
				>
					<section className={pageUtils.classes.sectionNoPadding}>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_required_documents}
						</h4>
						<span className={pageUtils.classes.fileInfo}>
							{pageAssets?.page_requestInfo_inputFileDesc}
						</span>

						{isBalconyOrPorchPermit(whatToDoInput.value) && (
							<>
								{renderBalconyRequestFiles('required', false)}
								{renderBalconyRequestFiles('optional', false)}
							</>
						)}
					</section>
				</ReactCollapse>
			)}
		</>
	)
}

export default LandDevelopmentWorkPermitForm
